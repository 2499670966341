/* Main container */
.companion-list-container {
    max-width: 800px; /* عرض البطاقة */
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff; /* لون الخلفية أبيض */
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* ظل جميل */
    text-align: left; /* محاذاة النص لليسار */
  }
  
  /* Title */
  .title-container {
    font-size: 30px; /* حجم أكبر للعنوان */
    font-weight: 700; /* جعل النص عريض */
    color: #1f1f1f; /* لون داكن */
    margin-bottom: 20px;
    text-align: center; /* محاذاة مركزية */
    white-space: nowrap;

  }
  
  /* Companion list */
  .companions-list {
    display: flex;
    flex-direction: column; /* ترتيب العناصر تحت بعض */
    gap: 20px; /* زيادة المسافة بين العناصر */
  }
  
  /* Companion card */
  .companion-card {
    background: #f9f9f9; /* لون خلفية فاتح */
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* ظل */
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #e0e0e0; /* حدود خفيفة */
  }
  
  .companion-card:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* تأثير عند التمرير */
    transform: translateY(-2px); /* تأثير التحريك */
  }
  
  /* Close button */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .close-btn svg {
    stroke: #ff4d4f; /* لون أحمر */
    transition: stroke 0.3s ease;
  }
  
  .close-btn:hover svg {
    background-color: rgba(0, 0, 0, 0.1); /* لون أسود خفيف */
}
  
  /* Companion details styling */
  .companion-details {
    display: flex;
    flex-direction: column;
    gap: 10px; /* تقليل المسافة بين العناصر */
  }
  
  .details-header {
    font-weight: 600; /* جعل النص عريض */
    font-size: 18px; /* حجم خط مناسب */
    color: #1f1f1f; /* لون داكن */
    border-bottom: 2px solid #e0e0e0; /* خط أسفل العنوان */
    padding-bottom: 10px; /* حشوة تحت العنوان */
  }
  
  /* Grid container for flight and pricing details */
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* أربعة أعمدة */
    gap: 10px; /* المسافة بين العناصر */
    margin-top: 10px; /* مساحة أعلى الشبكة */
  }
  
  /* Simple label value */
  .simple-label-value {
    display: flex;
    justify-content: space-between;
    font-size: 15px; /* حجم خط مناسب */
  }
  
  /* Pricing section */
  .pricing-section {
    margin-top: 15px;
    padding-top: 10px;
    border-top: 1px solid #eaeaea; /* خط يفصل بين التفاصيل والأسعار */
  }
  
  /* No data */
  .no-data {
    font-size: 20px;
    color: #777;
    margin-top: 30px;
    text-align: center; /* محاذاة مركزية */
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .companion-list-container {
      padding: 15px; /* تقليل الحشو في الأجهزة الصغيرة */
    }
  
    .grid-container {
      grid-template-columns: repeat(2, 1fr); /* عمودين في الأجهزة الصغيرة */
    }
  }
  