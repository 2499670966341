.admin-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 450px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;
  margin-top: 40px;
  .form-field {
    width: 100%;
    margin-bottom: 20px;

    .form-checkbox {
      display: flex;
      align-items: center;

      input {
        margin-right: 10px;
      }

      label {
        font-size: 16px;
        color: #333;
      }
    }
  }

  button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:focus {
      outline: none;
    }

    &.approve-button {
      background-color: #4caf50;
      color: white;
      margin-bottom: 10px;
      width: 200px;

      &:hover {
        background-color: #45a049;
      }

      &:active {
        background-color: #388e3c;
      }
    }

    &.reject-button {
      background-color: #f44336;
      color: white;
      margin-bottom: 10px;
      width: 200px;
      &:hover {
        background-color: #e53935;
      }

      &:active {
        background-color: #c62828;
      }
    }
  }

  .toast-message {
    font-size: 14px;
    color: #ff0000;
    padding: 5px;
    text-align: center;
    margin-top: 30px;
    background-color: #ffebee;
    border-radius: 5px;
    width: 100%;
    font-weight: bold;
  }

  .approve-button-reject-button {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}
