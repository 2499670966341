.invoice-trip-user {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Roboto", sans-serif;

  .participant-data {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
  }

  .participant-data {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  .participant-data :global(.simple-label-value) {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    background-color: #f8f8f8;
    border-radius: 4px;
    color: #333;
    font-size: 0.9rem;
  }

  .participant-data :global(.simple-label-value:nth-child(even)) {
    background-color: #f1f1f1;
  }

  .simple-label-value-label {
    font-weight: 600;
    color: #555;
  }

  .simple-label-value-value {
    color: #222;
  }

  p {
    font-size: 1rem;
    color: #777;
    text-align: center;
  }

  @media (max-width: 768px) {
    .invoice-trip-user {
      padding: 1rem;
    }

    .participant-data {
      padding: 1rem;
    }

    .simple-label-value {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .participant-data {
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
  }
  .tititi {
    font-size: 2rem;
    font-weight: bold;
    color: #d32f2f; /* Vibrant red for the text */
    text-align: center;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px solid #f44336; /* Lighter red for the underline */
    display: inline-block;
    padding-bottom: 0.5rem;
  }
}
