// ملف Sass للتصميم الاحترافي
$primary-color: #9B1321;
$button-padding: 10px 20px;
$card-radius: 10px;

.edit-welcome-msg-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f4f4f4;
  border-radius: 10px;

  .header-title {
    font-weight: bold;
    color: $primary-color;
    text-align: center;
  }

  .current-data {
    margin-top: 30px;

    .section-title {
      font-size: 1.5rem;
      color: $primary-color;
      margin-bottom: 10px;
    }

    .message-card, .image-card {
      margin-bottom: 20px;
      border-radius: $card-radius;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 20px;
      background-color: white;
    }

    .image-card {
      display: flex;
      flex-direction: column;
      align-items: center;

      input[type="file"] {
        margin-bottom: 10px;
        border: none;
        padding: 10px;
        background-color: #f1f1f1;
        border-radius: 5px;
        cursor: pointer;
      }

      img {
        max-width: 100%;
        height: auto;
        border-radius: $card-radius;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      padding: $button-padding;
      font-size: 1.2rem;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    padding: 15px;
    
    .header-title {
      font-size: 1.8rem;
    }

    .message-card, .image-card {
      margin-bottom: 15px;
    }

    .button-container {
      button {
        font-size: 1rem;
        padding: 8px 15px;
      }
    }
  }

  @media (max-width: 480px) {
    .message-card, .image-card {
      padding: 15px;
    }

    .button-container {
      button {
        width: 100%;
      }
    }
  }
}
