.add-option-btn {
  padding: 0px 20px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  color: #3b3b3b;
  border-radius: 5px;
  cursor: pointer;
  font-size: 15px;
  color: #ef233c;
}

.trip-form-container7 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
$primary-color: #4caf50;
$button-bg-color: #ff5722;
$input-bg-color: #f9f9f9;
$input-border-color: #ddd;
$button-hover-color: #e64a19;
$delete-btn-bg: #f44336;
$delete-btn-hover: #d32f2f;

.available-dates-manager {
  border-radius: 8px;
  background-color: #ffffff;

  .date-inputs {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .date-input-group {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .add-input-btn {
      padding: 8px 16px;
      background-color: $primary-color;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 10px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
  .remove-input-btn {
    margin: 10px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #d32f2f;
    }
  }
  .date-input-group-test {
    display: grid;
    grid-template-columns: 93% 5%;
    grid-column-gap: 10px;
    gap: 20px;
    align-items: center;
  }
  .delete-icon5 {
    margin-top: 27px;
    cursor: pointer;
  }
}
