.Toastify__toast-theme--light {
  background-color: black;
}

.Toastify__toast-body {
  color: #fff;
  font-size: 12px;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.layout-page-container {
  display: flex;
  flex-direction: row;
}

