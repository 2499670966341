.airport-transfer-form-section {
  overflow: overlay;
  padding: 20px;
  display: flex;

  justify-content: center;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
  }

  .airport-transfer-form,
  .booking-data {
    width: 100%;
    max-width: 900px;
    // margin: 20px;
    // background: #fff;
    // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    // padding: 20px;
    border-radius: 8px;
    overflow: hidden;
  }

  .airport-transfer-form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    margin: auto;
  }

  h3 {
    font-size: 24px;
    color: #9B1321;
    margin-bottom: 15px;
  }

  label {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 6px;
  }

  .form-action {
    display: flex;
    justify-content: center;
    align-items: center;

    .submit-btn {
      cursor: pointer;
      width: 200px;
      display: inline-block;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      background-color: #9B1321;
      border: none;
      border-radius: 5px;
      text-decoration: none;
      text-align: center;
      transition: background-color 0.3s, color 0.3s;
      margin-top: 30px;
    }

    .submit-btn:hover {
      background-color: #0056b3;
    }
  }

  .Select,
  .Input,
  .DateInput,
  .Checkbox {
    margin-bottom: 15px;
  }

  input:focus,
  select:focus,
  textarea:focus {
    border-color: #007bff;
    outline: none;
  }

  .invoice-section {
    padding: 15px;
    border-radius: 8px;
    text-align: center;

    h3 {
      color: #333;
      font-size: 22px;
    }

    p {
      font-size: 18px;
      color: #2e2e2e;
      margin: 20px 0;
    }

    .pay-btn {
      cursor: pointer;
      padding: 10px 20px;
      background-color: #28a745;
      color: white;
      border: none;
      border-radius: 5px;
      font-weight: bold;
      font-size: 16px;
      transition: background-color 0.3s;
    }

    .pay-btn:hover {
      background-color: #218838;
    }
  }

  .booking-data {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .booking-card {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding: 20px;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      .booking-details,
      .invoice-details {
        flex: 1;
        min-width: 250px;

        h4 {
          font-size: 20px;
          margin-bottom: 15px;
          color: #333;
        }

        p {
          font-size: 16px;
          color: #555;
          margin: 5px 0;
        }

        strong {
          font-weight: bold;
        }
      }

      .invoice-details {
        // background-color: #f9f9f9;
        border-radius: 8px;
        padding: 15px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        gap: 20px;

        .invoice-details-list {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        .pay-btn {
          width: 180px;
          background-color: #9B1321;
          color: white;
          padding: 10px 20px;
          font-size: 16px;
          border: none;
          cursor: pointer;
          border-radius: 5px;
          transition: background-color 0.3s;

          &:hover {
            background-color: #9f3225;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .airport-transfer-form,
    .booking-data {
      width: 90%;
      margin: 10px;
    }

    .booking-card {
      flex-direction: column;
    }

    .airport-transfer-form-container {
      padding: 20px;
    }

    .submit-btn {
      width: 100%;
    }
  }
  .booking-details-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
