.flight-information-steeper-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 30px;
  .check-in-input-container {
    margin-top: 25px;
  }
}

.flight-test {
  .content-wrapper {
    .children-container {
      height: auto !important;
    }
  }
}
