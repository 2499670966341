.flight-admin {
  border: 1px solid #d8dae5;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  .delete-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    cursor: pointer;
  }

  .check-in-input-container {
    padding: 10px 0px;
  }
  .room-form-stepper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
}

.add-flight-btn-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 10px;

  button {
    border: none;
    background-color: rgba(255, 251, 251, 0.49);
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    cursor: pointer;
    color: green;
  }
}
.view-main-user-flight {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-row-gap: 20px;
  border: 1px solid #d8dae5;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.ticket-pricing-form-container {
  border: 1px solid #d8dae5;
  border-radius: 5px;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
}
