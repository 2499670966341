.register-type-page {
  display: grid;
  grid-template-columns: 25% 75%;
  height: calc(100vh);
  overflow: hidden;
  .about-container {
    width: 100%;
    height: 100vh;

    .new-about-us-container {
      background-color: #ef233c;
      height: calc(100vh - 255px);
      color: #fff;
      padding: 20px;

      .titlee {
        font-weight: 600;
        font-size: 16px;
        padding-bottom: 20px;
      }
      .description {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
  .register-type-section {
    width: 100%;
    padding-top: 100px;
    .register-note {
      font-size: 15px;
      font-weight: 200;
      color: #ef233c;
      padding: 40px 60px;
      line-height: 25px;
    }
    .welcome-titlee {
      font-size: 22px;
      font-weight: 200;
      padding: 0px 60px;

      color: #ef233c;
      line-height: 25px;
    }
    .register-types {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 20px;
      gap: 15px;

      .type {
        height: 185px;
        width: 185px;
        border: 1px solid rgb(219, 219, 219);
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease, background-color 0.3s ease;

        .titlee {
          font-size: 13px;
          font-weight: 500;
          color: #ef233c;
          white-space: nowrap;
        }

        &:hover {
          background-color: rgb(241, 249, 255);
          transform: scale(0.98);
        }
      }
    }
  }
}
