$primary-color: #ef233c;
$hover-color: #d10000;
$secondary-color: #f3f3f3;
$font-family: "Roboto", sans-serif;
$border-radius: 8px;
$input-border: #ddd;
$checkbox-border: #888;
$checkbox-checked-color: $primary-color;

.edit-attendance-form {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  padding: 30px;
  border-radius: $border-radius;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: $font-family;

  .form-title {
    text-align: center;
    font-size: 24px;
    color: $primary-color;
    margin-bottom: 20px;
  }

  .checkbox-group {
    margin-bottom: 20px;

    .form-checkbox {
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      label {
        font-size: 16px;
        margin-left: 10px;
        color: #333;
        font-weight: 500;
        cursor: pointer;
      }

      .checkbox-label {
        color: #888;
        font-size: 14px;
        margin-top: 5px;
      }

      // input[type="checkbox"] {
      //   accent-color: $primary-color;
      //   width: 20px;
      //   height: 20px;
      //   margin-right: 15px;
      //   border-radius: 5px;
      //   border: 1px solid $checkbox-border;
      //   transition: all 0.3s ease;

      //   &:checked {
      //     background-color: $primary-color;
      //     border: 1px solid $primary-color;
      //   }

      //   &:hover {
      //     cursor: pointer;
      //   }
      // }
    }
  }

  .submit-btn {
    display: block;
    width: 100%;
    background-color: $primary-color;
    color: #fff;
    padding: 12px;
    font-size: 18px;
    text-align: center;
    border: none;
    border-radius: $border-radius;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $hover-color;
    }

    &:focus {
      outline: none;
    }
  }

  @media screen and (max-width: 768px) {
    .edit-attendance-form {
      padding: 20px;
    }

    .form-title {
      font-size: 22px;
    }
  }
}
