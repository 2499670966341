// متغيرات الألوان والخطوط
$primary-color: #c82333; // لون أحمر جذاب
$secondary-color: #dc3545; // أحمر فاتح
$error-color: #f44336; // أحمر واضح
$background-color: #f3f4f6; // رمادي فاتح
$border-color: #e0e0e0;
$text-color: #333; // رمادي غامق
$font-family: "Poppins", sans-serif; // خط حديث وأنيق

@mixin transition($property, $duration: 0.3s, $ease: ease-in-out) {
  transition: $property $duration $ease;
}

.register-page-container112 {
  display: flex;
  justify-content: center;
  align-items: center;

  .register-form {
    width: 100%;

    .title {
      font-size: 28px;
      font-weight: 700;
      color: $primary-color;
      text-align: center;
      margin-bottom: 20px;
      letter-spacing: 1px;
      text-transform: uppercase;
      border-bottom: 2px solid $primary-color;
      display: inline-block;
      padding-bottom: 10px;
    }

    .fields-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      .input-field,
      .select-field,
      .textarea-field,
      .upload-field {
        display: flex;
        flex-direction: column;

        label {
          font-size: 15px;
          font-weight: bold;
          color: $text-color;
          margin-bottom: 10px;
        }

        input,
        select,
        textarea {
          padding: 12px;
          font-size: 15px;
          color: $text-color;
          border: 2px solid $border-color;
          border-radius: 8px;
          font-family: $font-family;
          background-color: #fff;
          box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.05);

          @include transition(border-color, 0.3s);
          &:focus {
            outline: none;
            border-color: $primary-color;
            box-shadow: 0 0 8px rgba($primary-color, 0.4);
          }
        }

        textarea {
          resize: vertical;
          min-height: 120px;
        }

        .error-msg {
          font-size: 13px;
          color: $error-color;
          margin-top: 5px;
        }
      }
    }

    .register-btn-container {
      display: flex;
      justify-content: end;

      .register-btn {
        background: linear-gradient(135deg, $primary-color, $secondary-color);
        color: #fff;
        padding: 12px 25px;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: 5px;
        width: 200px;
        margin-top: -38px;
        cursor: pointer;
        box-shadow: 0 4px 10px rgba($primary-color, 0.3);

        @include transition(all, 0.4s);

        &:hover {
          background: linear-gradient(
            135deg,
            lighten($primary-color, 10%),
            lighten($secondary-color, 10%)
          );
          box-shadow: 0 6px 15px rgba($primary-color, 0.5);
        }

        &:active {
          transform: scale(0.98);
        }
      }
    }
  }
  .textarea-container .textarea-field-container .textarea-field {
    height: 100px;
  }
}

@media (max-width: 768px) {
  .register-page-container112 {
    padding: 10px;

    .register-form {
      padding: 20px;

      .fields-container {
        gap: 20px;
      }
    }
  }
}

@media (max-width: 480px) {
  .register-page-container112 {
    .register-form {
      .register-btn-container {
        .register-btn {
          font-size: 14px;
          padding: 10px 20px;
        }
      }
    }
  }
}
