.dialog-message-container {
  .children-container {
    width: 300px;
  }
  .dialog-container .children-container .title-container {
    border: none;
    margin: 0px;
  }
  .message-section {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    color: #333;
    margin:10px 30px;
  }
  button {
    cursor: pointer;
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #32cb00;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
    margin-top: 20px;

    &.close {
      background-color: rgba(255, 0, 0, 0);
      border: 1px solid #32cb00;
      color: #333;

      &:hover {
        color: #ffffff;
        background-color: #32cb00;
      }
      &:active {
        color: #ffffff;
        background-color: #165a00;
      }
    }

    &:hover {
      color: #ffffff;
      background-color: #71e54b;
    }
    &:active {
      background-color: #165a00;
    }
  }
  .dialog-message {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon-container-section {
    // border: 1px solid #ef233c;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .actions-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    align-items: center;
    margin: 0 20px;
    margin-bottom: 20px;
  }
}
