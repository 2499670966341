.conferences-page {
  padding: 0px 20px;
  height: calc(100vh - 102px);
  overflow: overlay;
  .view-con-container {
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbar-track-color);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--scrollbar-thumb-color);
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--scrollbar-thumb-hover-color);
    }
  }
  .conferences-form-admin-header {
    margin: 20px 0px;
    height: 95px;
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgb(242, 239, 239);
    .inputs-container {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
    .header-input {
      font-size: 19px;
      font-weight: 500;
      color: #fff;
      width: 400px;
    }

    .add-conferences-btn {
      // margin-top: 20px;
      padding: 0px 20px;
      height: 35px;
      background-color: #fff;
      border: 1px solid #ef233c;
      color: #3b3b3b;
      border-radius: 5px;
      cursor: pointer;
      font-size: 15px;
      &:hover {
        background-color: #ef233c;
        color: #fff;
      }
      &:active {
        background-color: #5f0000;
        color: #fff;
      }
    }
  }
  .header {
    width: 100%;
    height: 35px;
    color: #090043;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .conference-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 35px;
    align-items: center;
    justify-content: center;
  }
  .conference-item {
    gap: 20px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #eeeeee;
    width: 300px;
    .conference-image {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .titlee {
      font-size: 14px;
      font-weight: 500;
      color: #525252;
      margin-bottom: 15px;
      padding: 15px 10px;
      height: 25px;
      line-height: 22px;
    }
    .date {
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      color: #7d7d7d;
      margin-bottom: 15px;
      padding: 0px 10px;
    }
    .place {
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      color: #00003b;
      padding: 0px 10px;
    }
    .conference-image {
      height: 200px;
      width: 300px;
    }
    .actions-btns {
      margin-top: 20px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: #d3d3d32d;

      cursor: pointer;
      button {
        border: none;
        background-color: #fffbfb7c;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        cursor: pointer;
        &.view {
          color: #407bff;
        }
        &.edit {
          // color: #9109e0;
          color: #407bff;

        }
      }
    }
    .actions-btns2 {
      // margin-top: 20px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: #d3d3d32d;
      // display: flex;
      // flex-direction: row;
      // justify-content: center;
      cursor: pointer;
      button {
        border: none;
        background-color: #fffbfb7c;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        cursor: pointer;
        // padding-left: 60px;
        &.view {
          color: #407bff;
        }
        &.edit {
          color: #9109e0;
        }
      }
    }
  }
  .conference-details {
    width: 100%;

    .details-header {
      background-color: rgb(241, 241, 241);
      padding: 20px;
      font-size: 15px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 500;
    }
    .view-con-container {
      height: calc(100vh - 57px);
      overflow: overlay;
      overflow-x: hidden;
    }
    .new-section {
      border-bottom: 1px solid rgb(244, 244, 244);
      padding: 10px 20px;
      font-size: 12px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 500;
    }

    .info-details {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 20px 20px;
      border-bottom: 1px solid rgb(244, 244, 244);
      grid-row-gap: 20px;
    }
    .conference-details-container {
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: 1px solid rgb(244, 244, 244);

      .committee-member img {
        width: 100px;
        height: 100px;
        border-radius: 5px;
        margin-right: 10px;
      }
      .member-info {
        font-size: 12px;
        font-weight: 500;
        color: #989898;
        margin-bottom: 15px;
      }
    }
    .topics-container {
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .topic {
        font-size: 12px;
        font-weight: 500;
        color: #989898;
      }
    }

    .downloads-container {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      .simple-label-value-container {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 20px;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
  }

  .conference-form-container {
    padding: 20px;
  }
}
