$primary-color: #c82333;
$error-color: #c82333;
$success-color: #af0012;
.container-create-list {
  height: calc(100vh - 80px);
  padding: 20px;
  overflow: overlay;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
  }
}
.container-create-job {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 15px;
    // .desc{
    // }

    Input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;

      &:focus {
        border-color: $primary-color;
        box-shadow: 0 0 5px rgba($primary-color, 0.5);
      }
    }
  }

  button {
    width: 100%;
    padding: 10px;
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }

  .error {
    color: $error-color;
    text-align: center;
    margin-top: 10px;
  }

  .success {
    color: $success-color;
    text-align: center;
    margin-top: 10px;
  }
}
@media (max-width: 600px) {
  .container {
    padding: 15px;
  }

  h2 {
    font-size: 1.5em;
  }

  button {
    padding: 8px;
    font-size: 14px;
  }
}
