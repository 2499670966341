.card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 2px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    margin: 20px;
    transition: transform 0.3s ease-in-out;
    max-width: 990px;
  
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* تأثير الظل عند التحويم */
    }
  
    .card-image {
      position: relative;
      width: 50%;
      height: 100;
      overflow: hidden;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  
      .view-gallery-btn {
        position: absolute;
        top: 15px;
        right: 15px;
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        padding: 12px 25px;
        border: none;
        cursor: pointer;
        font-size: 16px;
        border-radius: 5px;
        opacity: 0;
        transition: opacity 0.3s, background-color 0.3s;
        z-index: 1;  /* التأكد من أن الزر في المقدمة */
        visibility: visible;
  
        &:hover {
          opacity: 1;
          background-color: #c82333; /* تغيير اللون عند التحويم */
        }
      }
    }
  
    .card-content {
      width: 50%;
      padding: 25px;
      text-align: left;
  
      h3 {
        font-size: 1.6rem;
        color: #333;
        font-weight: 600; /* جعل العنوان أكثر وضوحًا */
      }
  
      p {
        font-size: 1rem;
        color: #777;
        margin: 15px 0;
        line-height: 1.6; /* زيادة التباعد بين الأسطر */
      }
  
      .see-more-btn {
        background-color: #c82333; /* اللون الأحمر */
        color: #fff;
        padding: 12px 25px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        transition: background-color 0.3s, transform 0.3s;
  
        &:hover {
          background-color: #bd2130; /* لون داكن عند التحويم */
          transform: scale(1.05); /* تكبير الزر قليلاً عند التحويم */
        }
      }
    }
  }
  
  // التصميم المتجاوب مع الشاشات المختلفة
  @media (max-width: 768px) {
    .card {
      flex-direction: column;
      max-width: 100%;
      margin: 10px;
    }
  
    .card-image {
      width: 100%;
      height: 200px;
    }
  
    .card-content {
      width: 100%;
      padding: 15px;
      text-align: center;
    }
  
    .card-content h3 {
      font-size: 1.4rem;
    }
  
    .card-content p {
      font-size: 0.9rem;
    }
  
    .see-more-btn {
      padding: 10px 20px;
    }
  
    .view-gallery-btn {
      padding: 10px 20px;
      font-size: 14px;
    }
  }
  