@use "../../Global/style.scss";

.input-container {
  position: relative;
  .text-field-container {
    position: relative;

    .text-field {
      width: calc(100% - 34px);
      width: 100%;
      border: 1px solid var(--border-color);
      border-radius: 3px;
      height: 35px;
      outline: none;
      padding: 0px 15px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      
      &::placeholder {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #b4b6c5;
      }
      &.error-msg {
        border: 1px solid var(--error-static);
      }
    }

    .input-icon {
      position: absolute;
      top: 20%;
      right: 15px;
      fill: var(--element-color);
    }
  }

  .error-msg-container {
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--error-static);
  }
  .lable-container {
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: var(--element-color);

    .star {
      color: var(--error-static);
    }
  }
}
