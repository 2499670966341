.view-form-flight-details-admin {
  .header {
    background-color: rgb(241, 241, 241);
    padding: 20px;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
  }

  .view-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px;
    grid-row-gap: 20px;
    max-height: calc(100vh - 57px);
    overflow: overlay;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbar-track-color);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--scrollbar-thumb-color);
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--scrollbar-thumb-hover-color);
    }
  }
}
.add-trip-admin {
  .header {
    background-color: rgb(241, 241, 241);
    padding: 20px;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
  }

  .form-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    height: calc(100vh - 121px);
    width: 100%;

    overflow: overlay;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbar-track-color);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--scrollbar-thumb-color);
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--scrollbar-thumb-hover-color);
    }
  }
  .actions-section-container {
    height: 64px;
    background-color: rgb(235, 235, 235);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 0px 20px;
    .cancel-btn {
      width: 100%;
      height: 40px;
      background-color: #fff;
      border: 1px solid #ef233c;
      color: #3b3b3b;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #ef233c;
        color: #fff;
      }
      &:active {
        background-color: #c82333;
        color: #fff;
      }
      font-size: 14px;
    }
    .submit-btn {
      width: 100%;
      height: 40px;
      background-color: #ef233c;
      font-size: 14px;

      border: 1px solid rgb(222, 222, 222);
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #ef233c;
        color: #fff;
      }
      &:active {
        background-color: #c82333;
        color: #fff;
      }
    }
  }
}

.cost-form-container {
  .header {
    background-color: rgb(241, 241, 241);
    padding: 20px;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
  }

  .form-section {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: calc(100vh - 57px);
    overflow: overlay;
    height: calc(100vh - 161px);
    width: 100%;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbar-track-color);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--scrollbar-thumb-color);
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--scrollbar-thumb-hover-color);
    }
  }
  .actions-section-container {
    height: 64px;
    background-color: rgb(235, 235, 235);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    padding: 0px 20px;
    .cancel-btn {
      width: 100%;
      height: 40px;
      background-color: #fff;
      border: 1px solid #ef233c;
      color: #3b3b3b;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #ef233c;
        color: #fff;
      }
      &:active {
        background-color: #c82333;
        color: #fff;
      }
      font-size: 14px;
    }
    .submit-btn {
      width: 100%;
      height: 40px;
      background-color: #ef233c;
      font-size: 14px;

      border: 1px solid rgb(222, 222, 222);
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        background-color: #ef233c;
        color: #fff;
      }
      &:active {
        background-color: #c82333;
        color: #fff;
      }
    }
  }
}
.flight-form2 {
  height: calc(100vh - 60px);
  overflow: overlay;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
  }
}
.flight-form {
  .flight-form-admin-header {
    margin: 20px;
    height: 90px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgb(242, 239, 239);
    //  background-color:#c82333;

    .header {
      font-size: 19px;
      font-weight: 500;
      color: #fff;
      width: 400px;
    }
    .filter-btn {
      padding: 0px 20px;
      height: 35px;
      background-color: #fff;
      border: 1px solid #ef233c;
      color: #3b3b3b;
      border-radius: 5px;
      cursor: pointer;
      font-size: 15px;
      &:hover {
        background-color: #ef233c;
        color: #fff;
      }
      &:active {
        background-color: #c82333;
        color: #fff;
      }
    }
  }

  .flight-table-container {
    padding: 0px 20px;

    .table-actions-container {
      button {
        padding: 0px 20px;
        height: 35px;
        background-color: #ffffff00;
        border: none;
        color: #3b3b3b;
        border-radius: 5px;
        cursor: pointer;
        font-size: 15px;
        // color: #ef233c;
        color: blue;
        &.price-btn {
          color: #e701cc;
        }

        &.trip-btn {
          color: rgb(255, 123, 0);
        }
      }
    }
  }
}
