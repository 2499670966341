.visa-page-container {
  text-align: center;
  padding: 40px;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 50px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  .visa-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
  }
  .question-container {
    margin: 20px 0;

    h2 {
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #333;
    }

    .button-group {
      display: flex;
      justify-content: center;
      gap: 20px;

      .yes-btn,
      .no-btn {
        padding: 12px 30px;
        font-size: 18px;
        font-weight: bold;
        border-radius: 8px;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.2s;
        width: 148px;

        &:hover {
          transform: translateY(-3px);
        }
      }

      .yes-btn {
        background-color: #000; /* اللون الأسود */
        color: white;
        width: 148px;

        &:hover {
          background-color: #333; /* درجة أفتح من الأسود */
        }
      }

      .no-btn {
        background-color: #dc3545;
        color: white;
        width: 148px;

        &:hover {
          background-color: #c82333;
        }
      }
    }
  }

  .visa-form {
    margin-top: 0px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;

    .fields-container {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .submit-btn {
      background-color: #cc0000; /* Primary button color */
      color: white;
      border: none;
      padding: 12px 30px;
      cursor: pointer;
      font-size: 16px;
      border-radius: 8px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }

      &.disabled{
        background-color: #ddd;
        
     
        color: #ccc;
        cursor: not-allowed;
      }
    }

    .error-msg {
      color: red;
      font-weight: bold;
    }
  }
  .next-button {
    background-color: #cc0000;
    color: white;
    border: none;
    padding: 12px 30px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 8px;
    transition: background-color 0.3s;
    margin-top: 30px;
    &:hover {
      background-color: #790000;
    }
  }

  .title-visa-2 {
    color: #cc0000;;
  }
}
