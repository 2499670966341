.form-container99{
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  
  h2 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .speaker-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  input[type="file"],
  input[type="text"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  input[type="checkbox"] {
    margin-top: 10px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
    
    &:hover {
      background-color: #0056b3;
    }
  }
  
  .existing-data {
    text-align: center;
    margin-bottom: 20px;
    p {
      font-size: 18px;
      margin-bottom: 10px;
    }
  
    .edit-button {
      padding: 10px 20px;
      background-color: #28a745;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
      
      &:hover {
        background-color: #218838;
      }
    }
  }
  
  .response-message {
    text-align: center;
    color: green;
    font-weight: bold;
    margin-top: 20px;
  }
}