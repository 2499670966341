$max-width: 800px;
$background-color: #f9f9f9;
$text-color: #333;
$subheading-color: #ef233c;
$hover-color: #555;
$padding: 20px;
$margin: 15px;
$border-radius: 10px;

.faq-container-section {
  height: calc(100vh - 58px);
  overflow: overlay;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
  }

  .faq-container {
    max-width: $max-width;
    margin: 20px auto;
    padding: $padding;
    font-family: Arial, sans-serif;
    border-radius: $border-radius;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);

    h1 {
      text-align: center;
      color: $text-color;
      margin-bottom: $margin;
    }

    h2 {
      color: $subheading-color;
      margin-top: $margin;
    }

    h3 {
      color: $hover-color;
      margin: $margin 0 5px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    p,
    ul,
    ol {
      line-height: 1.6;
      margin: $margin 0;
    }

    ul,
    ol {
      padding-left: 20px;
    }

    li {
      margin-bottom: 5px;
    }

    @media (max-width: 768px) {
      padding: 15px;

      h1 {
        font-size: 1.5em;
      }

      h2 {
        font-size: 1.2em;
      }

      h3 {
        font-size: 1em;
      }
    }

    @media (max-width: 480px) {
      padding: 10px;

      h1 {
        font-size: 1.2em;
      }

      h2 {
        font-size: 1.1em;
      }

      h3 {
        font-size: 0.9em;
      }

      p {
        font-size: 0.9em;
      }
    }
  }
}
