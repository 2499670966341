.flight-form-stepper-container {
  border: 1px solid #d8dae5;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .delete-icon-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    cursor: pointer;
  }

  .flight-form-stepper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 30px;
  }
}

.add-flight-btn-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 10px;

  button {
    border: none;
    background-color: rgba(255, 251, 251, 0.49);
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    cursor: pointer;
    color: green;
  }
}
.add-room-btn-container77 {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: end;
  display: flex;

  button {
    background-color: #d8dae500;
    color: green;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
.delete-icon-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: end;
  cursor: pointer;
}

.room-form-stepper-container {
  .room-form-stepper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
  }
}
