

.FlightDetails {
  .custom-form-wrapper .form-section {
    padding: 0px !important;
    height: calc(99vh - 70px) !important;
  }
  .FlightDetails-section {
    display: grid;
    grid-template-columns: 1FR 1FR;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border-bottom: 1px solid gray;
  }

  .head{
    padding: 10px 20px;
    // height: 60px;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #9a9a9a2c;
    border-top: 1px solid #9a9a9a2c;
  }

  .no-data{
    font-size: 20px;
    color: #777;
    margin-top: 30px;
    text-align: center; 
  }
}
.companion-modal{
  .custom-form-wrapper .form-section {
    padding: 0px;
    display: flex
;
    flex-direction: column;
    gap: 20px;
    height: calc(99vh - 61px);
    overflow: overlay;
    background-color: #fff;
}
}