.stepperContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &.horizontal {
    flex-direction: row;
  }
}

.stepContainer {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // width: auto;

  &.horizontalStepContainer {
    flex-direction: row;
    align-items: end;

    .splitInHalfHor {
      position: relative;
      width: 50%;
      height: 100%;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 50%; /* Adjusted width to 50% */
        height: 100%;
        border-radius: 2px;
        background-color: #142452;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 50%; /* Adjusted width to 50% */
        height: 100%;
        background-color: #d8dae5;
      }
    }
  }

  .step {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    color: white;

    &.horizontalStep {
      flex-direction: column-reverse;
    }

    .outerCircle {
      width: 32px;
      height: 32px;
      background-color: #b7bacc;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .active {
      background-color: #142452;
      color: white;
    }
    .completed {
      background-color: #3aaa35;
      color: white;
    }

    .disabled {
      cursor: not-allowed;
    }

    .stepTitle {
      color: #828699;
    }
  }

  .stepSeparator {
    width: 2px;
    background-color: #ebecf2;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-inline-start: 14px;
    margin-inline-end: 0;

    &.horizontalSeparator {
      height: 2px;
      margin-top: 0;
      margin-bottom: 14px;
      margin-inline-start: 5px;
      margin-inline-end: 5px;
    }
  }
  .completedSeparator {
    background-color: #3aaa35;
  }
  .activeSeparator {
    background-color: #142452;
  }
  .splitInHalfVer {
    position: relative;
  }

  .splitInHalfVer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    border-radius: 2px;
    background-color: #142452;
  }

  .splitInHalfVer::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #d8dae5;
  }
}
