// Base styles for the form
$primary-color: #007bff;
$secondary-color: #f1f1f1;
$input-border-color: #ccc;
$input-focus-border-color: $primary-color;
$btn-bg-color: #007bff;
$btn-text-color: #fff;
$btn-hover-bg-color: #0056b3;
$font-family: 'Arial', sans-serif;

// Reset some default styles
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $font-family;
  color: #333;
}

.transportation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  min-height: 100vh;
}

.transportation-form {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 800px;

  h2 {
    text-align: center;
    font-size: 2rem;
    color: $primary-color;
    margin-bottom: 30px;
  }

  .form-group {
    margin-bottom: 20px;

    &.checkbox-group {
      display: flex;
      justify-content: space-between;
      label {
        display: flex;
        align-items: center;
        font-size: 1rem;
      }
    }

    label {
      font-size: 1rem;
      font-weight: 600;
      display: block;
      margin-bottom: 8px;
    }

    input[type='text'],
    input[type='email'],
    input[type='number'],
    textarea {
      width: 100%;
      padding: 12px;
      border: 1px solid $input-border-color;
      border-radius: 6px;
      font-size: 1rem;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: $input-focus-border-color;
        outline: none;
      }

      &::placeholder {
        color: #aaa;
      }
    }

    textarea {
      resize: vertical;
      min-height: 150px;
    }

    .input-group {
      display: inline-block;
      width: 48%;
      margin-right: 4%;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .note {
    font-size: 0.9rem;
    color: #777;
    margin-top: 15px;
    text-align: center;
  }

  .submit-btn {
    width: 100%;
    padding: 12px;
    font-size: 1.1rem;
    background-color: $btn-bg-color;
    color: $btn-text-color;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $btn-hover-bg-color;
    }
  }

  @media (max-width: 768px) {
    .form-group {
      .input-group {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    .note {
      font-size: 0.85rem;
    }
  }
}
