@use "../../Global/style.scss";
.image-upload-container {
  .upload-file {
    display: none;
  }

  .label-container {
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: var(--element-color);

    .star {
      color: var(--error-static);
    }
  }
  .img-upload-container {
    .upload-img-container {
      height: 38px;
      width: calc(100%);
      border: 1px solid var(--border-color);
      border-radius: 5px;
      outline: none;
      padding-left: 15px;
      background-color: #fff;
      transition: border 0.2s ease-out;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &.error-msg {
        border: 1px solid var(--error-static);
      }

      .placholder {
        font-size: 14px;
        color: #babdcc;
        cursor: pointer;

        .file-name {
          color: rgba(18, 19, 26, 1);
          font-family: Arial, Helvetica, sans-serif;
          font-size: 13px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
        }
      }

      svg {
        padding-right: 15px;
        path {
          stroke: var(--element-color);
        }
      }
    }

    .img-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;
      .img {
        height: 37px;
        padding: 5px;
        border: 1px solid var(--border-color);
        border-radius: 5px;
      }
      .actions-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        svg {
          cursor: pointer;
        }
        .view-icon {
          fill: var(--element-color);
        }
      }
    }
    .error-msg-container {
      font-size: 11px;
      font-weight: 400;
      line-height: 17px;
      text-align: left;
      color: var(--error-static);
    }
  }
}
