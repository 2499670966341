// NotificationMessage.scss

.notification-container-msg2 {
    display: flex;
    align-items: center;
    max-width: 600px;
    margin: 100px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-left: 5px solid #d32f2f;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    
    &:hover {
      transform: translateY(-5px);
    }
    
    // Responsive adjustments
    @media (max-width: 768px) {
      padding: 15px;
      flex-direction: column;
      text-align: center;
    }
    
    @media (max-width: 480px) {
      padding: 10px;
    }
  
    .notification-icon {
      font-size: 40px;
      color: #d32f2f;
      margin-right: 15px;
      
      @media (max-width: 768px) {
        font-size: 35px;
        margin: 0 0 10px 0;
      }
  
      @media (max-width: 480px) {
        font-size: 30px;
      }
    }
  
    .notification-content {
      color: #333;
    }
  
    .notification-title {
      font-size: 1.2em;
      color: #d32f2f;
      margin: 0 0 5px;
      font-weight: bold;
      
      @media (max-width: 768px) {
        font-size: 1.1em;
      }
  
      @media (max-width: 480px) {
        font-size: 1em;
      }
    }
  
    .notification-text {
      font-size: 1em;
      color: #555;
      line-height: 1.5;
      
      @media (max-width: 768px) {
        font-size: 0.95em;
      }
  
      @media (max-width: 480px) {
        font-size: 0.9em;
      }
    }
  }
  