// Container for all exhibition cards

.user-exhibition-cards-container {
  height: calc(100vh - 58px);
  overflow: overlay;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
  }

  .exhibition-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    padding: 0px 20px;
    grid-column-gap: 20px !important;
  }
  .filter-section {
    display: grid;
    grid-template-columns: 300px 300px;
    grid-column-gap: 20px;
    padding: 20px;
  }
  // Individual card
  .exhibition-card {
    text-align: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    margin: 10px 0px !important;
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
    .two-btn{
      margin-bottom: 10px;
    }
  }

  // Card image section
  .exhibition-card-image {
    width: 100%;
    height: 200px;
    background-color: #f3f3f3;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .exhibition-placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 1.2rem;
      color: #777;
      background: #e0e0e0;
    }
  }

  // Card details section
  .exhibition-card-details {
    flex: 1;
    padding: 15px;

    .exhibition-card-title {
      font-size: 1.5rem;
      font-weight: 600;
      color: #333;
      margin: 0 0 10px;
      line-height: 1.2;
    }

    .exhibition-card-description {
      font-size: 1rem;
      color: #555;
      margin-bottom: 10px;
      line-height: 1.5;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .exhibition-card-location {
      font-size: 0.9rem;
      font-weight: 500;
      color: #666;
    }
  }

  // Page title
  .page-title {
    font-size: 2rem;
    font-weight: 700;
    color: #444;
    text-align: center;
    margin: 20px 0;
  }
  .btn {
    background-color: #9B1321;
    color: white;
    padding: 12px 10px;
    margin-left: 10px;
    border-radius: 4px;
    border: none;
    transition: all 0.3s ease;  /* تأثيرات الانتقال لتكون سلسة */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* إضافة تأثير shadow */
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #a3271b;  /* تغيير اللون عند التمرير */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);  /* تكبير الظل عند التمرير */
  }
  
  .btn:active {
    transform: translateY(2px);  /* التحرك قليلًا للأسفل عند النقر */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  /* تقليل الظل عند النقر */
  }
  
}
