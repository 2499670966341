.adventure-section {
    font-family: Arial, sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
  
    .adventure-hero {
      position: relative;
      width: 100%;
      height: 400px;
  
      .hero-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
  
      .hero-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 2.5rem;
        color: white;
        font-weight: bold;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      }
    }
  
    .features-container {
      display: flex;
      justify-content: center;
      margin-top: 10px;
  
      .feature-item {
        margin: 0 20px;
  
        .circle {
          width: 200px;
          height: 200px;
          background-color: #fff;
          border: 3px solid #d9534f; // لون الحدود
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px;
          font-size: 1.2rem;
          color: #333;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease;
  
          &:hover {
            transform: scale(1.05); // تكبير عند التمرير
          }
        }
      }
    }
  }
  