// لوحة الألوان
$primary-color: #c82333; // اللون الأحمر
$secondary-color: #ffffff;
$background-color: #f9f9f9;
$accent-color: #ff5722;
$text-color: #333;
$box-shadow: rgba(0, 0, 0, 0.1);

// الحاوية الرئيسية
.container555 {
  margin: auto;
  max-width: 600px;
  // margin: 30px;
  margin-top: 30px;
  background: $secondary-color;
  border-radius: 12px;
  box-shadow: 0 6px 15px $box-shadow;
  padding: 30px;
  overflow: hidden;

  h3 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    color: $primary-color;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 50px;
      height: 3px;
      background: $accent-color;
      margin: 10px auto 0;
    }
  }

  // حقل الإدخال
  input[type="file"] {
    display: block;
    width: 100%;
    padding: 1rem;
    margin: 1rem 0;
    font-size: 1rem;
    color: $primary-color;
    background-color: #fff;
    border: 2px dashed lighten($primary-color, 20%);
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: lighten($primary-color, 40%);
      border-color: $accent-color;
    }
  }

  // زر الإرسال
  button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    font-size: 1.2rem;
    font-weight: bold;
    color: $secondary-color;
    background: $primary-color;
    border: none;
    border-radius: 8px;
    margin-top: 20px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background: darken($primary-color, 10%);
    }

    &:disabled {
      background: lighten($primary-color, 30%);
      cursor: not-allowed;
    }
  }

  // الرسائل
  .message {
    text-align: center;
    margin-top: 1rem;
    padding: 0.75rem;
    font-size: 1rem;
    border-radius: 8px;

    &.success {
      background: lighten($primary-color, 40%);
      color: darken($primary-color, 20%);
    }

    &.error {
      background: lighten($accent-color, 40%);
      color: darken($accent-color, 20%);
    }
  }

  // نقاط التوقف (Responsive)
  @media (max-width: 768px) {
    body {
      padding: 1rem;
    }

    .container {
      max-width: 90%;
      padding: 1.5rem;
    }

    h3 {
      font-size: 1.6rem;
    }

    button {
      font-size: 1rem;
      padding: 0.6rem;
    }

    input[type="file"] {
      font-size: 0.9rem;
      padding: 0.8rem;
    }
  }

  @media (max-width: 480px) {
    .container {
      padding: 1rem;
    }

    h3 {
      font-size: 1.4rem;
    }

    button {
      font-size: 0.9rem;
      padding: 0.5rem;
    }

    input[type="file"] {
      font-size: 0.8rem;
      padding: 0.6rem;
    }
  }
}
