.dialog-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #1424525f;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    z-index: 1300;
  }

  .content-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1400;
    background-color: transparent;
    overflow: hidden;
  }

  .children-container {
    width: 500px;
    background-color: white;
    border-radius: 10px;
    z-index: 10000;

    .title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 20px;
      width: calc(100% - 40px);
      height: 67px;
      border-bottom: 1px solid var(--border-color);
      white-space: nowrap;
      width: 100%;
      svg {
        cursor: pointer;
      }
    }
  }
}
