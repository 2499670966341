.contact-us-section {
  height: calc(100vh - 58px);
  overflow: overlay;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
  }
  .contact-us-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    h2 {
      text-align: center;
      margin-bottom: 20px;
      color: #333;
    }

    .contact-form {
      display: flex;
      flex-direction: column;

      .form-group {
        margin-bottom: 15px;

        label {
          font-weight: bold;
          margin-bottom: 5px;
          color: #666;
        }

        .form-input,
        .form-textarea {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 5px;
          transition: border 0.3s ease;

          &:focus {
            border-color: #dc143c; // اللون الأحمر الجميل
            outline: none;
          }
        }

        .form-textarea {
          height: 100px;
          resize: none;
        }
      }

      .send-button {
        padding: 10px;
        background-color: #c82333; // اللون الأحمر الجميل
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken(#dc143c, 10%);
        }
      }
    }

    .contact-info {
      margin-top: 20px;
      text-align: center;
      color: #666;

      h3 {
        margin-bottom: 10px;
      }
    }
  }
}
