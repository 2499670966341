.one-exhibit-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
    background-color: #f5f5f5;
  
    @media (max-width: 768px) {
      gap: 15px;
      padding: 10px;
    }
  
    .exhibit-item {
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
  
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
      }
  
      .exhibit-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-bottom: 2px solid #f0f0f0;
  
        @media (max-width: 768px) {
          height: 180px;
        }
      }
  
      .exhibit-title {
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        padding: 10px 15px;
        color: #333;
        background-color: #f9f9f9;
        transition: color 0.3s ease, background-color 0.3s ease;
  
        &:hover {
          color: #007bff;
          background-color: #e9ecef;
        }
      }
    }
  }
  