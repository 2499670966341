
.user-conference-cards-container {
  height: calc(100vh - 58px);
  overflow: overlay;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
  }

  .conference-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    //   padding-left: 30px;
    padding: 0px 20px;
    grid-column-gap: 20px !important;
  }

  // Individual card
  .conference-card {
    text-align: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    margin: 10px 0px !important;
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
  }

  // Card image section
  .conference-card-image {
    width: 100%;
    height: 200px;
    background-color: #f3f3f3;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .conference-placeholder {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 1.2rem;
      color: #777;
      background: #e0e0e0;
    }
  }

  // Card details section
  .conference-card-details {
    flex: 1;
    padding: 15px;

    .conference-card-title {
      font-size: 1.5rem;
      font-weight: 600;
      color: #333;
      margin: 0 0 10px;
      line-height: 1.2;
    }

    .conference-card-description {
      font-size: 1rem;
      color: #555;
      margin-bottom: 10px;
      line-height: 1.5;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .conference-card-dates {
      font-size: 0.9rem;
      font-weight: 500;
      color: #888;
      margin-bottom: 5px;
    }

    .conference-card-location {
      font-size: 0.9rem;
      font-weight: 500;
      color: #666;
    }
  }

  // Page title
  .page-title {
    font-size: 2rem;
    font-weight: 700;
    color: #444;
    text-align: center;
    margin: 20px 0;
  }
}
