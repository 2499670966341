.conference-detailss {
  background: #f9fafb;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 2fr 7fr;
  .side-drawer {
    background-color: #2c3e50;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    height: calc(100vh - 57px);
    overflow: overlay;
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbar-track-color);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--scrollbar-thumb-color);
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--scrollbar-thumb-hover-color);
    }
    .drawer-item {
      background: #34495e;
      color: #ecf0f1;
      padding: 15px;
      font-size: 16px;
      font-weight: 500;
      border: none;
      text-align: left;
      border-radius: 8px;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        background: #c62828;
      }

      &.active {
        background: #c62828;
        color: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .main-content {
    .content {
      h2 {
        font-size: 28px;
        color: #2c3e50;
        margin-bottom: 15px;
      }

      h3 {
        font-size: 20px;
        color: #34495e;
      }

      p {
        color: #7f8c8d;
        line-height: 1.8;
        width: 100%;
        max-width: 500px;
        margin: 20px auto;
        box-sizing: border-box;
        overflow-wrap: break-word;
      }

      .info-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        div {
          padding: 20px;
          background: #ecf0f1;
          border-radius: 8px;
        }
      }

      .card {
        padding: 20px;
        margin: 15px 0;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-5px);
        }

        h3 {
          margin-bottom: 10px;
        }

        img {
          max-width: 100px;
          border-radius: 50%;
          margin-bottom: 10px;
        }
      }

      .btn-download {
        display: inline-block;
        margin-top: 20px;
        padding: 10px 20px;
        background: #c62828;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        border-radius: 8px;
        transition: background 0.3s ease;
        border: none;

        &:hover {
          background: #df3e3e;
        }
      }

      .conference-image {
        max-width: 100%;
        border-radius: 8px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      }
      .img-conference {
        width: 370px;
        height: auto;
        border-radius: 12px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        object-fit: cover;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
      }
    }
    .image-with-content {
      display: flex;
      flex-direction: row;
      gap: 30px;

      h2 {
        text-align: start;
      }
    }
  }

  // Document Section Styles
  .document-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    max-width: 600px;
    width: 100%;
    text-align: center;

    .document-header {
      font-size: 1.5rem;
      font-weight: bold;
      color: #3498db;
      margin-bottom: 1rem;
    }

    .document-info {
      font-size: 1.1rem;
      color: #555;
      margin-bottom: 1.5rem;
    }

    .document-preview {
      font-size: 3rem;
      color: #3498db;
      margin-bottom: 1.5rem;
    }

    .btn-download {
      padding: 0.8rem 1.5rem;
      background-color: #c62828;
      color: #fff;
      font-size: 1.1rem;
      font-weight: 600;
      text-decoration: none;
      border-radius: 5px;
      transition: background-color 0.3s ease, transform 0.3s ease;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #c71400;
        transform: scale(1.05);
      }

      i {
        margin-right: 0.5rem;
      }
    }

    .no-document {
      font-size: 1.1rem;
      color: #e74c3c;
      margin-top: 1rem;
    }

    .desc {
      padding: 20px 0px;
    }
  }

  // Icon for PDF (you can use FontAwesome or similar)
  .icon-file-pdf {
    font-size: 4rem;
    color: #e74c3c;
    margin-bottom: 1rem;
  }
  
}
/* Container holding all the workshop cards */
.workshop-container999 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  padding: 20px;
}

/* Individual card for each workshop */
.workshop-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.workshop-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

/* Description text style */
.workshop-description p {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
  text-align: justify;
}

// style.scss

.accommodation-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.accommodation-container .title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #0A74DA;
  margin-bottom: 20px;
}

.room-prices {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 16px;
  margin-top: 20px;
}

.room-price {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.room-price:hover {
  transform: translateY(-8px);
}

.room-price h6 {
  font-size: 1.2rem;
  color: #0A74DA;
}

.room-price h5 {
  font-size: 1.5rem;
  color: #FF7043;
  margin-top: 10px;
}

.register-btn {
  display: block;
  margin: 30px auto;
  background-color: #0A74DA;
  color: white;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 1.2rem;
  width: 200px;
  text-align: center;
  transition: background-color 0.3s ease;
  border: none;
}

.register-btn:hover {
  background-color: #FF7043;
  cursor: pointer;
}

@media (max-width: 600px) {
  .room-prices {
    grid-template-columns: 1fr;
  }
}
