// متغيرات الألوان
$white: white;
$black: #000000;
$dark-gray: #333;
$medium-gray: #555;
$hover-color: rgb(179, 50, 50);

// القواعد العامة لبطاقة المؤتمر
.conference-card {
  display: flex;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 80%;
  overflow: hidden;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-5px); // تأثير التحويم
  }
}

// صورة العنوان
.headline-picture2 {
  background-size: cover;
  background-position: center;
  width: 200px;
  height: 200px;
  flex-shrink: 0;
}

// محتوى البطاقة
.card-content {
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex-grow: 1;
  position: relative;

  .card-title {
    font-size: 20px; // حجم الخط أصغر قليلًا
    font-weight: bold;
    margin: 10px 0;
    color: $dark-gray;
  }

  .card-description {
    font-size: 16px;
    line-height: 1.6;
    color: $medium-gray;
    flex-grow: 1;
  }

  .read-more-button {
    display: flex;
    align-items: center;
    margin-top: auto;
    padding: 12px 30px;
    background-color: $black;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    height: 30px;
    font-weight: 600;
    width: 120px;
    margin-right: 50px;
    margin-top: 20px; // تحديث المسافة العلوية

    &:hover {
      background-color: $hover-color;
      color: black;
    }
  }
}

// التعديلات التكيفية
@media (max-width: 768px) {
  .conference-card {
    flex-direction: column;
    width: 100%;
  }

  .headline-picture2 {
    width: 100%;
    height: 200px;
  }
}
