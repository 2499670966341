// أساسيات التصميم
.active-registrations {
    max-width: 1500px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    h1 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 1.8rem;
      color: #333;
    }
  
    .error {
      color: red;
      text-align: center;
      margin-top: 10px;
    }
  }
  
  // جدول البيانات
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 1rem;
    text-align: left;
  
    thead {
      // background-color: #007bff;
      color: #ffffff;
  
      th {
        padding: 12px 8px;
        text-align: left;
        font-weight: bold;
      }
    }
  
    tbody {
      tr {
        &:nth-child(even) {
          background-color: #f9f9f9;
        }
  
        &:hover {
          background-color: #f1f1f1;
        }
  
        td {
          padding: 12px 8px;
          border: 1px solid #ddd;
  
          a {
            color: #007bff;
            text-decoration: none;
            font-weight: bold;
  
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  
  // التحكم في الترقيم (Pagination)
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
  
    button {
      padding: 8px 16px;
      font-size: 0.9rem;
      font-weight: bold;
      color: #ffffff;
      background-color: #007bff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
  
      &:disabled {
        background-color: #ccc;
        color: #666;
        cursor: not-allowed;
      }
    }
  
    span {
      font-size: 1rem;
      font-weight: bold;
      color: #333;
    }
  }
  
  // تصميم استجابة الشاشات الصغيرة
  @media (max-width: 768px) {
    .table {
      font-size: 0.9rem;
  
      thead {
        th {
          font-size: 0.85rem;
          padding: 8px;
        }
      }
  
      tbody {
        td {
          font-size: 0.85rem;
          padding: 8px;
        }
      }
    }
  
    .pagination {
      flex-direction: column;
      gap: 10px;
  
      button {
        width: 100%;
      }
  
      span {
        font-size: 0.85rem;
      }
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 10px;
  
      h1 {
        font-size: 1.5rem;
      }
    }
  
    .pagination button {
      font-size: 0.8rem;
      padding: 6px 12px;
    }
  }
  