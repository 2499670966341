  .add-participant-button-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    cursor: pointer;
  }

  .add-button-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    cursor: pointer;
    button {
      border: none;
      background-color: rgba(255, 251, 251, 0.4862745098);
      font-size: 14px;
      font-weight: 500;
      height: 40px;
      cursor: pointer;
      color: green;
    }
  }

  .delete-button-participant {
    display: flex;
    flex-direction: row;
    justify-content: end;
    cursor: pointer;
  }
  .participant-member-one {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 30px;
  }

  .participant-member {
    border: 1px solid #d8dae5;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
