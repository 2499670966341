/* Base styling for the PayForm container */
.pay-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
  color: #333;

  .pay-form-title {
    font-size: 24px;
    font-weight: bold;
    color: #4b4f58;
    margin-bottom: 20px;
  }

  .invoice-list {
    list-style-type: none;
    padding: 0;

    .invoice-item {
      background-color: #f9f9f9;
      padding: 15px;
      margin-bottom: 15px;
      border-radius: 6px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

      .invoice-title {
        font-size: 18px;
        color: #007bff;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        margin: 5px 0;
        color: #555;
      }

      strong {
        color: #4b4f58;
      }
    }
  }

  .pay-now-button {
    display: inline-block;
    padding: 12px 20px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }

    &:focus {
      outline: none;
    }
  }

  .loading {
    text-align: center;
    font-size: 18px;
    color: #007bff;
    margin-top: 20px;
  }

  .invoice-details {
    display: flex;
    flex-direction: row;
    gap: 50px;
  }
}
.lessta {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
