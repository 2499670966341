.trips-page {
  padding: 0px 20px;
  height: calc(100vh - 102px);

  overflow: overlay;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .trip-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(202px, 1fr));
    gap: 20px;

    .trip-card {
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      width: 202px;
      max-height: 290px;

      .trip-image {
        width: 100%;
        height: 120px;
        object-fit: cover;
      }

      .trip-info {
        .desc {
          font-size: 14px;
          font-weight: 500;
          color: #7d7d7d;
          margin-bottom: 15px;
          line-height: 22px;
        }
        .name {
          font-size: 14px;
          font-weight: 500;
          color: #525252;
          margin-bottom: 15px;
          line-height: 22px;
        }
        .main-info {
          padding: 15px 10px;
          height: 76px;
        }
        .actions-btns {
          margin-top: 20px;
          width: 100%;
          display: grid;
          grid-template-columns: 1fr;
          background-color: #d3d3d32d;

          cursor: pointer;
          button {
            border: none;
            background-color: #fffbfb7c;
            font-size: 14px;
            font-weight: 500;
            height: 40px;
            cursor: pointer;
            &.view {
              color: #407bff;
            }
            &.edit {
              color: #9109e0;
            }
          }
        }
      }
    }
  }

  .trips-users-container {
    margin: 20px 0px;
    padding: 20px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgb(242, 239, 239);
    .trips-types-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      button {
        display: inline-block;
        padding: 10px 20px;
        background-color: #ef233c;
        color: white;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 1px;
        border-radius: 5px;
        text-decoration: none;
        transition: background-color 0.3s ease-in-out;
        cursor: pointer;
        border: none;
        &:hover {
          background-color: darken(#ef233c, 10%);
        }
      }
    }
    .header-input {
      font-size: 19px;
      font-weight: 500;
      color: #fff;
      width: 400px;
    }

    .register-for-trip-btn {
      padding: 0px 20px;
      height: 35px;
      background-color: #fff;
      border: 1px solid #ef233c;
      color: #3b3b3b;
      border-radius: 5px;
      cursor: pointer;
      font-size: 15px;
      &:hover {
        background-color: #ef233c;
        color: #fff;
      }
      &:active {
        background-color: #c82333;
        color: #fff;
      }
    }
  }
}
