:root {
  --primary-color: #3a6cd1;
  --secondary-color: #ebecf2;
  --tertiary-color: #9a9eb2;
  --background-color: #f8f8fb;
  --border-color: #d8dae5;
  --text-color: #515360;
  --hover-color: #f1f2f69f;
  --shadow-color: rgba(0, 0, 0, 0.26);
  --scrollbar-track-color: #f1f1f1;
  --scrollbar-thumb-color: #888;
  --scrollbar-thumb-hover-color: #555555ac;
  --main-color: #fafafa;
  --element-color: rgb(20, 36, 82);
  --error-static: #da3f3f;
  --error-hover: #e75d5d;
  --error-active: #bb3232;
}
