/*** 

====================================================================
    Color Palate Style / Color Switcher Style
====================================================================

***/

.color-palate {
    background: #fff none repeat scroll 0 0;
    -webkit-box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
    position: fixed;
    left: -285px;
    text-align: center;
    top: 25%;
    transition: all 0.5s ease 0s;
    width: 285px;
    z-index: 999;
    padding-bottom: 40px;
}

.color-palate-head {
    background: #9B1321 none repeat scroll 0 0;
    padding: 15px 0;
    margin-bottom: 30px;
}

.color-palate-head h6,
.secondary-head h6 {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    margin: 0px;
    margin-top: 4px;
    text-transform: uppercase;
}

.palate {
    background: red none repeat scroll 0 0;
    display: block;
    float: left;
    height: 45px;
    margin: 0 2.5% 12px;
    width: 20%;
    cursor: pointer;
    position: relative;
}

.colors-list .active::after {
    /* background: url(../images/icons/tick.png) center center no-repeat !important; */
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.various-color {
    overflow: hidden;
    padding: 20px 0 15px;
}

.colors-list {
    margin: 0 20px;
}

.secondary-head {
    background: #222222 none repeat scroll 0 0;
    padding: 14px 0;
}

.secondary-color {
    padding: 23px 0;
}

.secondary-colors-list {
    margin: 0 80px;
}

.palate-foo {
    color: #777777;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 30px;
    line-height: 1.8em;
}

.palate.default-color {
    background: #9B1321 none repeat scroll 0 0;
}

.palate.green-color {
    background: #2ECC40 none repeat scroll 0 0;
}

.palate.blue-color {
    background: #0074D9 none repeat scroll 0 0;
}

.palate.brown-color {
    background: #ab8b40 none repeat scroll 0 0;
}

.palate.purple-color {
    background: #bb54e1 none repeat scroll 0 0;
}

.palate.teal-color {
    background: #14c599 none repeat scroll 0 0;
}

.palate.orange-color {
    background: #ff7e00 none repeat scroll 0 0;
}

.palate.redd-color {
    background: #FF4136 none repeat scroll 0 0;
}

.color-trigger {
    background: #9B1321 none repeat scroll 0 0;
    cursor: pointer;
    height: 50px;
    right: -46px;
    position: absolute;
    top: 0;
    width: 46px;
    padding-top: 6px;
    z-index: 1;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.color-trigger i {
    -webkit-animation: fa-spin 2s linear infinite;
    animation: fa-spin 2s linear infinite;
    -moz-animation: fa-spin 2s linear infinite;
    -ms-animation: fa-spin 2s linear infinite;
    -o-animation: fa-spin 2s linear infinite;
}

.color-trigger:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    color: #ffffff;
    font-size: 18px;
    line-height: 50px;
    content: "\f00d";
    font-family: "Font Awesome 5 Free";
    background-color: #9B1321;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    font-weight: 900;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.color-trigger i {
    color: #ffffff;
    font-size: 18px;
    line-height: 40px;
}

.color-palate.visible-palate {
    left: 0px;
}

.color-palate.visible-palate .color-trigger {
    right: 0;
}

.color-palate.visible-palate .color-trigger:before {
    opacity: 1;
    visibility: visible;
}

.color-palate .option-box {
    position: relative;
    padding: 0 15px;
    margin-bottom: 10px;
}

.color-palate .option-box:after {
    display: table;
    content: "";
    clear: both;
}

.color-palate .option-box li {
    position: relative;
    float: left;
    width: 46%;
    margin: 0 2%;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 18px;
    text-align: center;
    background-color: #9B1321;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.color-palate .option-box li:last-child {
    margin-right: 0;
}

.color-palate .option-box li:hover {
    background-color: #222222;
}

.color-palate .purchase-btn {
    position: relative;
    display: block;
    color: #ffffff;
    font-size: 14px;
    line-height: 30px;
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 0px 10px;
    background-color: #000000;
    max-width: 150px;
    margin: 10px auto 5px;
    border-radius: 5px;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.color-palate .purchase-btn:hover {
    background-color: #9B1321;
}