.flights-with-invoices {
    background-color: #f4f6f9;
    padding: 30px;
    font-family: 'Roboto', sans-serif;
    color: #333;
  
    .main-heading {
      font-size: 2.4rem;
      color: #333;
      font-weight: 600;
      margin-bottom: 20px;
    }
  
    .section-heading {
      font-size: 1.8rem;
      color: #555;
      font-weight: 500;
      margin-top: 30px;
      margin-bottom: 15px;
    }
  
    .flights-list, .companions-list {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  
    .flight-container {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      overflow: hidden;
  
      &:hover {
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
      }
    }
  
    .flight-details {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 15px;
      margin-bottom: 20px;

    }
  
    .invoice-header {
      font-size: 1.4rem;
      color: #333;
      margin-top: 15px;
      font-weight: 600;
    }
  
    .loading-message, .error-message {
      font-size: 1.2rem;
      color: #f44336;
      font-weight: 500;
    }
  
    .no-data-message {
      font-size: 1.2rem;
      color: #888;
    }
  
    .no-flights-message, .no-companions-message {
      font-size: 1.2rem;
      color: #777;
      font-weight: 400;
    }
  
    @media (max-width: 768px) {
      padding: 20px;
  
      .main-heading {
        font-size: 2rem;
      }
  
      .section-heading {
        font-size: 1.6rem;
      }
  
      .flight-container {
        padding: 15px;
      }
  
      .flight-details {
        grid-template-columns: 1fr;
      }
    }
  }
  