.invoice-container-section {
  .room-info,
  .main-room-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
  }

  h3 {
    color: #ef233c !important;
    margin-bottom: 30px;
  }
  .other-rooms-section {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .section-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    margin-bottom: 20px;
  }
}
