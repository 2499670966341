$primary-color: #ef233c;
$secondary-color: #fce4e4;
$accent-color: #c0392b;
$font-color: #2c3e50;
$background-color: #fafafa;
$border-radius: 8px;
$transition-speed: 0.3s;
.speaker-section-container {
  // height: calc(100vh - 58px);
  // overflow: overlay;
  // overflow-x: hidden;
  // &::-webkit-scrollbar {
  //   width: 3px;
  // }

  // &::-webkit-scrollbar-track {
  //   background: var(--scrollbar-track-color);
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: var(--scrollbar-thumb-color);
  //   border-radius: 50px;
  // }

  // &::-webkit-scrollbar-thumb:hover {
  //   background: var(--scrollbar-thumb-hover-color);
  // }

  .checkbox-label {
    margin-right: 10px;
  }
  .speaker-profile-section-container {
    display: flex;
    justify-content: center;
    padding: 0rem 1rem;

    .speaker-profile-form {
      margin-top: 20px;

      background-color: #fff;
      border-radius: $border-radius;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 0.1rem 2rem;
      max-width: 700px;
      width: 100%;
      transition: transform $transition-speed ease;

      .profile-container-img {
        display: flex;
        align-items: center;
        margin-bottom: 0rem;

        .profile-section {
          display: flex;
          align-items: center;

          .profile-image-speakerr {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin-right: 1rem;
            object-fit: cover;
            border: 2px solid $primary-color;
          }

          .profile-details {
            .profile-name {
              font-size: 1.4rem;
              font-weight: bold;
              color: $font-color;
              padding: 20px;
              margin-top: 30px;
            }

            .profile-bio {
              font-size: 1rem;
              color: darken($font-color, 20%);

              .bio {
                padding: 10px 20px;
                width: 100%;
                max-width: 500px;
                margin: 5px auto;
                box-sizing: border-box;
                overflow-wrap: break-word;
              }
            }
          }
        }
      }

      .profile-files {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .image-upload {
          margin-bottom: 1rem;
          .custom-label {
            color: $primary-color;
            font-weight: 600;
          }
        }

        .attendance-option {
          margin-top: 2rem;
          background-color: $secondary-color;
          padding: 1rem;
          border-radius: $border-radius;
          border: 1px solid lighten($font-color, 30%);

          .attendance-title {
            font-size: 1.2rem;
            font-weight: 600;
            color: $font-color;
            margin-bottom: 0.5rem;
          }

          .attendance-checkboxes {
            display: flex;
            gap: 1rem;
            margin-top: 0.5rem;

            .attendance-checkbox {
              display: flex;
              align-items: center;
            }
          }

          .notice {
            color: $accent-color;
            font-size: 0.9rem;
            margin-top: 0.8rem;
            font-style: italic;
          }
        }

        .topic-section {
          margin-top: 2rem;

          .topics-container {
            display: flex;
            flex-direction: column;

            .topic-title {
              font-size: 1.2rem;
              font-weight: 600;
              color: $primary-color;
            }

            .topics-container-inputs {
              margin-top: 0.8rem;

              .topic-input-container {
                display: flex;
                align-items: center;
                margin-bottom: 0.5rem;
                gap: 0.5rem;

                .topic-input {
                  flex: 1;
                  padding: 0.6rem;
                  border: 1px solid lighten($font-color, 30%);
                  border-radius: $border-radius;
                  transition: border-color $transition-speed;

                  &:focus {
                    border-color: $primary-color;
                  }
                }

                .delete-icon {
                  width: 20px;
                  height: 20px;
                  cursor: pointer;
                  transition: transform $transition-speed;

                  &:hover {
                    transform: scale(1.2);
                    fill: $accent-color;
                  }
                }
              }
            }

            .add-topic-btnn {
              margin-top: 1rem;
              background-color: #ffffff00;
              border: none;
              cursor: pointer;
              color: #ef233c;
            }
          }
        }
      }

      .update-btn {
        margin-right: 30px;
        margin-top: 20px;
        margin-bottom: 20px;
        width: 270px;
        height: 45px;
        background-color: #cc0000; // Red background
        color: #fff; // White text color
        border: none;
        border-radius: 4px;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;

        &:hover {
          background-color: #cc0000; // Darker red on hover
        }

        &:active {
          background-color: #990000; // Even darker red on active
          transform: scale(0.98); // Slightly shrink the button on click
        }
        &.disabled {
          background-color: #d6d6d6;
          color: #a1a1a1;
          cursor: not-allowed;
          opacity: 0.7;
          &:hover {
            background-color: #d6d6d6;
          }
        }
      }
    }
  }
}
