@use "../../Global/style.scss";

.login-page-container {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  height: 100vh;
  overflow: hidden;
  background-color: #d3d3d31d;

  .login-img {
    position: relative;
    height: 100%;
  }
  .login-form {
    width: 100%;
    margin-top: 4%;
    border-radius: 10px;
    padding: 30px 0px;
    height: auto;
    display: flex;
    flex-direction: column;

    .title {
      font-size: 30px;
      font-weight: 700;
      line-height: 50px;
      text-align: left;
      padding: 0px 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: #ef233c;
      margin-bottom: 25px;
      .sub-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 50px;
        text-align: left;
        padding: 0px 20px;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .note {
      padding: 15px 20px;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
    }
    .fields-container {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 15px 20px;
      padding: 0px 30px;
    }
  }

  .input-container {
    height: 70px;
    width: 70%;
  }

  .login-btn-container {
    display: flex;
    flex-direction: row;
    padding-left: 30px;

    .login-btn {
      cursor: pointer;
      width: 200px;
      display: inline-block;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      background-color: #ef233c;
      border: none;
      border-radius: 5px;
      text-decoration: none;
      text-align: center;
      transition: background-color 0.3s, color 0.3s;
      margin-top: 30px;
    }

    .login-btn:hover {
      background-color: #fe4c60;
      color: #ffffff;
    }
    .login-btn:active {
      background-color: #ce0018;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 768px) {
  .login-page-container {
    align-items: start;

    .login-form {
      margin-top: 40%;
      box-shadow: none;
    }
  }
}
