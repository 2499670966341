// الألوان
$primary-color: #000; // الأسود الأساسي
$secondary-color: #f5f5f5; // الرمادي الفاتح
$accent-color: #d32f2f; // الأحمر الجذاب
$text-color: #333;
$button-hover-color: lighten($primary-color, 10%);
$border-radius: 8px;
$input-padding: 10px;
$transition-speed: 0.3s;
$font-family: "Arial", sans-serif;

.admin-update-container {
  background: $secondary-color;
  border-radius: $border-radius;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  margin: 30px auto;

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }

  h2 {
    color: $primary-color;
    margin-bottom: 20px;
    font-size: 1.8rem;

    @media (max-width: 768px) {
      font-size: 1.4rem;
    }
  }

  button {
    background-color: $accent-color;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: $border-radius;
    cursor: pointer;
    font-size: 16px;
    transition: background-color $transition-speed;
    margin: 10px;
    min-width: 80px;

    &:hover {
      background-color: darken($accent-color, 10%);
    }

    &:disabled {
      background-color: lighten($accent-color, 30%);
      cursor: not-allowed;
    }

    @media (max-width: 480px) {
      padding: 10px 15px;
      font-size: 14px;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .date-input-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  input[type="text"] {
    width: 100%;
    padding: $input-padding;
    margin-top: 10px;
    border: 1px solid lighten($text-color, 40%);
    border-radius: $border-radius;
    font-size: 16px;

    @media (max-width: 480px) {
      font-size: 14px;
      padding: 8px;
    }
  }

  button.submit-btn {
    background-color: $primary-color;
    color: white;
    margin-top: 20px;
    padding: 12px 20px;
    border-radius: $border-radius;
    font-size: 16px;
    cursor: pointer;
    transition: background-color $transition-speed;

    &:hover {
      background-color: $button-hover-color;
    }

    @media (max-width: 480px) {
      width: 100%;
      padding: 10px;
    }
  }
}
