.booth-cost-form {
  padding: 20px;
  font-family: "Arial", sans-serif;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 15px auto;

  h2 {
    color: #333;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .form-message {
    text-align: center;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
  }

  .form-message.success {
    background-color: #d4edda;
    color: #155724;
  }

  .form-message.error {
    background-color: #f8d7da;
    color: #721c24;
  }

  .submit-button {
    padding: 12px 20px;
    background-color: #b22222;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }

  .submit-button:hover {
    background-color: #9a1c1c;
  }

  input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
  }

  input:focus {
    border-color: #b22222;
    outline: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
