// Colors
$primary-color: #333; // رمادي داكن
$secondary-color: #38302f; // أحمر داكن
$background-color: #f4f4f4; // رمادي فاتح
$card-bg-color: #fff; // أبيض
$shadow-color: rgba(0, 0, 0, 0.1); // ظل ناعم
$hover-bg-color: rgba(192, 57, 43, 0.1); // تأثير عند التحويم
$font-family: "Arial", sans-serif; // خط بسيط

.dinner-details3 {
  // max-height: calc(100vh - 57px);
  // overflow: overlay;

  // &::-webkit-scrollbar {
  //   width: 3px;
  // }

  // &::-webkit-scrollbar-track {
  //   background: var(--scrollbar-track-color);
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: var(--scrollbar-thumb-color);
  //   border-radius: 50px;
  // }

  // &::-webkit-scrollbar-thumb:hover {
  //   background: var(--scrollbar-thumb-hover-color);
  // }
  .dinner-detail-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  .dinner-details-container {
    max-width: 900px; // عرض الحاوية
    margin: 20px auto; // تقليل الهوامش العلوية والسفلية
    padding: 30px; // حشو داخلي
    border-radius: 8px; // زوايا دائرية
    box-shadow: 0 2px 10px $shadow-color; // ظل خفيف
    font-family: $font-family; // خط

    .dinner-title {
      color: #9B1321;
      font-size: 1.7em; // حجم الخط
    }

    // Section for Invoice Display
    .invoice-section {
      background-color: $card-bg-color;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 6px $shadow-color;
      margin-bottom: 20px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      
      h3 {
        font-size: 1.8em;
        color: $secondary-color;
        margin-bottom: 10px;
      }

      .invoice-item {
        margin-bottom: 10px;
        font-size: 1.1em;

        strong {
          color: $primary-color;
        }

        span {
          color: darken($primary-color, 20%);
        }
      }

      .pay-now-btn {
        width: 100%;
        height: 45px;
        background-color: #c83222;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        margin-top: 20px;

        &:hover {
          background-color: darken(#c83222, 10%);
        }
      }
    }

    // Dinner Details Section
    .dinner-detail-item {
      background-color: $card-bg-color; // لون خلفية البطاقة
      padding: 20px; // حشو داخلي
      border-radius: 5px; // زوايا دائرية
      box-shadow: 0 1px 5px $shadow-color; // ظل خفيف
      margin-bottom: 15px; // هامش سفلي
      display: flex; // استخدام flexbox
      justify-content: space-between; // توزيع العناصر
      align-items: center; // محاذاة العناصر

      strong {
        color: $primary-color; // لون النص
        font-size: 1.2em; // حجم الخط
        font-weight: bold; // سمك الخط
      }

      span {
        color: darken($primary-color, 20%); // لون نص ثانوي
        font-size: 1em; // حجم الخط
      }
    }

    // Attendance Confirmation Section
    .attendance-section {
      margin-top: 30px; // هامش علوي
      border-top: 2px solid $secondary-color; // خط فاصل
      padding-top: 20px; // حشو علوي

      h3 {
        color: $secondary-color; // لون العنوان
        font-size: 1.8em; // حجم الخط
        margin-bottom: 10px; // هامش سفلي
      }

      .price {
        font-weight: bold;
        color: black;
        font-size: 20px;
        margin: 15px 0;
      }

      button {
        width: 100%; // جعل الزر يملأ الحاوية
        margin-top: 20px;
        height: 40px;
        background-color: #c83222; // لون الزر
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;

        &:hover {
          background-color: darken(
            $secondary-color,
            10%
          ); // تغيير اللون عند التحويم
        }
      }
    }
  }
  .pay-now-btn {
    position: relative;
    right: 0px;
    width: 300px;
    height: 45px;
    background-color: #c83222;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;

    &:hover {
      background-color: darken(#c83222, 10%);
    }
  }
  .btn {
    padding: 10px 40px;
    border: none;
    background-color: #c83222;
    color: white;
    margin-left: 20px;
    border-radius: 4px;
    margin-bottom: 3px;
    cursor: pointer;
    &:hover {
      background-color: darken(#c83222, 10%);
    }
  }
  .fixed-button {
    position: fixed;
    bottom: 20px;    /* Distance from the bottom of the viewport */
    right: 20px;     /* Distance from the right side of the viewport */
    background-color: #9B1321;  /* Button color */
    color: white;    /* Text color */
    padding: 10px 20px; /* Padding for the button */
    font-size: 16px;  /* Font size */
    border: none;    /* Remove border */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for the button */
    cursor: pointer; /* Pointer cursor on hover */
    z-index: 1000;   /* Ensure it's above other elements */
  }
  
  .fixed-button:hover {
    background-color: #0056b3; /* Darker button color on hover */
  }
  
}

// Responsive Adjustments
@media (max-width: 768px) {
  .dinner-title {
    font-size: 2em; // تقليل حجم الخط للشاشات الأصغر
  }

  .dinner-detail-item {
    flex-direction: column; // تغيير اتجاه العناصر
    align-items: flex-start; // محاذاة العناصر لليسار
  }
}

@media (max-width: 480px) {
  .dinner-details-container {
    padding: 15px; // تقليل الحشو
  }

  .dinner-title {
    font-size: 1.6em; // تقليل حجم الخط
  }
}
