.exhibition-details-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;

  .view-exhibition-image {
    height: 37px;
    padding: 5px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
  }
}
