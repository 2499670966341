@use "../../Global/style.scss";

.textarea-container {
  position: relative;

  .label-container {
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: var(--element-color);

    .star {
      color: var(--error-static);
    }
  }
  .textarea-field-container {
    position: relative;

    .textarea-field {
      width: calc(100% - 0px);
      border: 1px solid var(--border-color);
      border-radius: 3px;
      height: 35px;
      outline: none;
      padding-inline-start: 15px;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      resize: none;
      height: 140px;
      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: var(--scrollbar-track-color);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--scrollbar-thumb-color);
        border-radius: 50px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--scrollbar-thumb-hover-color);
      }
      &::placeholder {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #b4b6c5;
      }
      &.error-msg {
        border: 1px solid var(--error-static);
      }
    }

    .textarea-icon {
      position: absolute;
      top: 20%;
      right: 15px;
      fill: var(--element-color);
    }
  }

  .error-msg-container {
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--error-static);
  }
}
