.certificate-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;

  
    .certificate-header {
      font-size: 24px;
      font-weight: 600;
      color: #333;
      margin-bottom: 20px;
      text-align: center;
    }
  
    .certificate-link {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
  
      a {
        font-size: 16px;
        font-weight: 500;
        color: #3498db;
        text-decoration: none;
        transition: color 0.3s ease;
        display: inline-flex;
        align-items: center;
  
        &:hover {
          color: #2980b9;
        }
      }
  
      .pdf-icon {
        margin-right: 8px;
        font-size: 20px;
      }
    }
  
    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 200px;
  
      .spinner {
        border: 4px solid #f3f3f3;
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
      }
    }
  
    .error-message {
      color: #e74c3c;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }
  
    .pdf-viewer {
      margin-top: 20px;
      width: 100%;
      height: 500px;
  
      iframe {
        border: none;
      }
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  