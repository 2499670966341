$primary-color: #3498db;
$secondary-color: #2ecc71;
$border-radius: 8px;
$input-padding: 10px;
$button-bg: #e74c3c;
$button-hover-bg: #c0392b;

.travel-form-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;

  .travel-form {
    background: #fff;
    padding: 2rem;
    border-radius: $border-radius;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  h2 {
    color: $primary-color;
    text-align: center;
    margin-bottom: 2rem;
  }

  .form-group {
    margin-bottom: 1.5rem;

    label {
      display: block;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      color: #333;
    }

    input,
    select,
    textarea {
      width: 100%;
      padding: $input-padding;
      border: 1px solid #ccc;
      border-radius: $border-radius;
      font-size: 1rem;
    }

    input[type="file"] {
      border: none;
      padding: 0.5rem;
    }
  }

  .submit-btn {
    background-color: $button-bg;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: $border-radius;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: $button-hover-bg;
    }
  }
}
@media (max-width: 768px) {
  .travel-form {
    padding: 1.5rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .submit-btn {
    padding: 10px 18px;
  }
}

@media (max-width: 480px) {
  .travel-form {
    padding: 1rem;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 0.9rem;
    padding: 8px;
  }
}
