.transportation-booking {
    font-family: Arial, sans-serif;
    text-align: center;
    color: #333;
  
    .booking-hero {
      position: relative;
      width: 100%;
      height: 400px;
  
      .hero-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(70%); // تقليل السطوع للصورة الخلفية
      }
  
      .hero-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 3rem;
        color: #fff;
        font-weight: bold;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
        letter-spacing: 2px;
      }
    }
  }
  