.sidebar{
  margin-top: 66px
}
.sidebar ul li button {
  background-color: transparent;
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
  font-size: 16px;

  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar ul li button:hover {
  background-color: #f0f0f0;
}

.sidebar ul li button.active {
  background-color: #007bff;
  color: white;
}
/* إضافة بعض التنسيق الأساسي لتقسيم الصفحة */
.conference-page {
  display: flex; /* استخدام Flexbox لتقسيم الصفحة */
  height: 100vh; /* جعل الصفحة تأخذ كامل ارتفاع الشاشة */
}

/* تصميم الشريط الجانبي */
.sidebar {
  width: 250px; /* عرض الشريط الجانبي */
  background-color: #f8f9fa; /* خلفية فاتحة للشريط الجانبي */
  padding: 20px; /* إضافة بعض الحواف داخل الشريط الجانبي */
  position: fixed; /* جعل الشريط الجانبي ثابتاً في الجهة اليسرى */
  height: 100vh; /* الشريط الجانبي يأخذ كامل ارتفاع الشاشة */
  top: 0;
  left: 0;
}

.sidebar ul {
  list-style-type: none; /* إزالة النقاط من القوائم */
  padding: 0;
  height: auto;
  overflow: overlay;
}

.sidebar ul li {
  margin-bottom: 10px; /* إضافة مسافة بين العناصر */
}
/* تصميم الروابط مثل الأزرار */
.sidebar ul li a {
  display: block; /* جعل الرابط يعرض ككتلة ليتحمل عرض كامل */
  background-color: transparent;
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none; /* إزالة التسطير الافتراضي */
  color: inherit; /* الحفاظ على لون النص الذي قد تم تعيينه في الأزرار */
}

.sidebar ul li a:hover {
  background-color: #f0f0f0; /* نفس تأثير التمرير للأزرار */
}

.sidebar ul li a.active {
  background-color: #007bff;
  color: white; /* تغيير اللون عندما يكون الرابط نشطًا */
}

.sidebar ul li button {
  background-color: transparent;
  border: none;
  padding: 10px;
  width: 100%;
  text-align: left;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sidebar ul li button:hover {
  background-color: #f0f0f0;
}

.sidebar ul li button.active {
  background-color: #007bff;
  color: white;
}

/* تصميم المحتوى الرئيسي */
.main-content {
  // margin-left: 250px; /* إزاحة المحتوى الرئيسي ليتناسب مع عرض الشريط الجانبي */
  padding: 20px;
  width: 100%;
  overflow-y: auto; /* إضافة تمرير عمودي إذا كانت المحتويات طويلة */
}

/* تصميم أقسام المحتوى داخل الصفحة */
.hero-section,
.details-section,
.topics-section,
.prices-section,
.documents-section {
  margin-bottom: 40px; /* إضافة مسافة بين الأقسام */
  
}

/* تصميم قسم التفاصيل */
.details-section .container,
.topics-section .container,
.prices-section .container,
.documents-section .container {
  max-width: 1200px; /* تحديد أقصى عرض للمحتوى */
  margin: 0 auto;
}

.hero-image {
  width: calc(100vw - 240px);
    height: 350px;
}

.hero-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* خلفية مظللة للنصوص على الصورة */
  color: white;
  padding: 20px;
}
.second-section{
  width: calc(100vw - 240px);
  // margin-left:calc(100vw - 290px) ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 857px;
  // width: 100%;
}