// الألوان
$primary-color: #c82333; // الأحمر
$secondary-color: #212121; // الأسود
$background-color: #f5f5f5; // السكني الفاتح
$hover-color: darken($primary-color, 10%);

// إعدادات الخطوط
$font-family: 'Arial', sans-serif;

.job-list {
  padding: 30px;
  font-family: $font-family;
  background-color: $background-color;
  border-radius: 10px;
  max-width: 1200px;
  margin: 0 auto;

  h2 {
    text-align: center;
    font-size: 36px;
    color: $secondary-color;
    margin-bottom: 20px;
  }

  .error-message {
    color: $primary-color;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }

  .job-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;

    // responsive design for smaller screens
    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  .job-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 300px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    }

    h3 {
      font-size: 24px;
      color: $secondary-color;
      margin-bottom: 15px;
      font-weight: bold;
    }

    p {
      font-size: 14px;
      color: #555;
      margin-bottom: 10px;
    }

    .submit-button {
      background-color: $primary-color;
      color: white;
      border: none;
      padding: 10px 15px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      width: 100%;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: $hover-color;
      }
    }
  }

  .application-form {
    margin-top: 20px;
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    margin-top: 20px;

    h3 {
      text-align: center;
      font-size: 24px;
      color: $secondary-color;
      margin-bottom: 15px;
    }

    form {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }

    .ant-input {
      border-radius: 5px;
      padding: 10px;
      font-size: 16px;
    }

    input[type="text"], input[type="email"], input[type="number"] {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      font-size: 16px;
      transition: border-color 0.3s;
    }

    input[type="text"]:focus, input[type="email"]:focus, input[type="number"]:focus {
      border-color: $primary-color;
    }

    .submit-button {
      background-color: $primary-color;
      color: white;
      padding: 12px;
      border-radius: 5px;
      border: none;
      font-size: 18px;
      cursor: pointer;
      width: 100%;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: $hover-color;
      }
    }
  }
}

// تنسيق التصميم المتجاوب (responsive)
@media (max-width: 1200px) {
  .job-card {
    width: 280px;
  }
}

@media (max-width: 992px) {
  .job-card {
    width: 260px;
  }
}

@media (max-width: 768px) {
  .job-list {
    padding: 20px;
  }

  .job-cards {
    justify-content: center;
  }

  .job-card {
    width: 100%;
    max-width: 350px;
  }

  .application-form {
    padding: 20px;
  }

  h3 {
    font-size: 20px;
  }
  .thank-you-message {
    background-color: #f1f8e9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    text-align: center;
    font-size: 18px;
    color: #212121;
  
    p {
      margin: 10px 0;
    }
  }
  
}

@media (max-width: 480px) {
  h2 {
    font-size: 28px;
  }

  .job-card {
    width: 100%;
    padding: 15px;
  }

  .application-form {
    padding: 15px;
  }

  .submit-button {
    font-size: 16px;
    padding: 10px;
  }

}
