.sponsor-section {
  // padding: 15px;
  color: #333333;
  border-radius: 8px;
  overflow: overlay;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
  }

  .sponsorship-options {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .sponsorship-option {
    flex: 1 1 calc(50% - 15px);
    min-width: 220px;
    border: 1px solid #b22222;
    padding: 15px;
    border-radius: 8px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }

  .sponsorship-option:hover {
    background-color: #e0e0e0;
    transform: translateY(-2px);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }

  .selected {
    border-color: #007bff;
  }

  .option-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .price {
    font-size: 1.2em;
    color: #b22222;
  }

  .booth-package {
    border-radius: 8px;
    .booth-package-header8 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }

    .lst-sponser {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-top: 20px;
      li {
        font-size: 13px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
      }

      .desc-sponser {
        font-size: 13px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        margin-top: 10px;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }

  .sign-agreement-button,
  .submit-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin: 0 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }

  .sign-agreement-button {
    background-color: #007bff;
    color: #fff;
  }

  .sign-agreement-button:hover {
    background-color: #0056b3;
  }

  .submit-button {
    background-color: #b22222;
    color: #fff;
  }

  .submit-button:hover {
    background-color: #c82333;
  }

  .booth-cost-table {
    margin-top: 15px;
  }
  .booth-cost-table-header3 {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
  }
  .con-booth-cost-table-table {
    padding: 20px;
  }

  .con-booth-cost-table-table,
  .booth-cost-table-description {
    padding: 0px 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid #d1d1d1;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f0f4f8;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .view-floor-plans-btn {
    display: inline-block;
    margin-top: 15px;
  }

  .view-floor-plans-button {
    background-color: #007bff;
    color: white;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }

  .view-floor-plans-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }

  .view-floor-plans-button:active {
    background-color: #004085;
    transform: translateY(0);
  }

  .input-container {
    margin: 10px 0;
  }

  .input-label {
    font-size: 16px;
    margin-bottom: 4px;
    display: block;
    color: #444;
  }

  .input-field {
    width: 50%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 15px;
    box-sizing: border-box;
    background-color: #f8f8f8;
  }

  .input-field:focus {
    border-color: #007bff;
    outline: none;
    background-color: #fff;
  }

  .agreement-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .popup-content {
    background: white;
    padding: 15px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
  }

  .popup-buttons {
    display: flex;
    justify-content: space-between;
  }

  .popup-buttons button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .btn-sign {
    background-color: #4c6baf;
    color: white;
  }

  .btn-cancel {
    background-color: #f44336;
    color: white;
  }

  .agreement-status {
    margin-top: 15px;
    font-size: 14px;
    color: green;
  }

  .shell-scheme {
    padding: 20px;
  }
  .shell-scheme-text {
    padding-left: 20px;
  }

  .booth-package-header7 {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
  }
}
.sponsorship-option-container0 {
  display: grid;
  flex-direction: column;
  padding: 8px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
  flex: 1;
  height: 100%;

  


  &.selected {
    border-color: #2579da;
    background-color: #f0f9ff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    border-color: #2196f3;
    background-color: #e3f2fd;
  }

  .option-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;

    h3 {
      font-size: 14px;
      margin: 0;
      color: #333;
      font-weight: bold;
    }

    .option-price {
      font-size: 14px;
      color: #757575;
      font-weight: bold;
    }
  }

  .option-container-description {
    font-size: 12px;
    color: #616161;
    line-height: 1.4;
    margin-bottom: 6px;
  }

  .option-container-checkbox {
    margin-top: 4px;
    input[type="checkbox"] {
      transform: scale(0.9);
      accent-color: #2579da;
    }
  }
}


.header-sponsorship-opportunities {
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 20px;
}

.button-container-list {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 20px;

  .Sign-Agreement-button{
    background-color: #28a745; /* Green background */
    color: #fff; /* White text */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    padding: 5px 20px; /* Padding for size */
    font-size: 16px; /* Text size */
    cursor: pointer;
  }
  
}
/* Popup styles */
.agreement-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.popup-content h3 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

.popup-content p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.input-fields {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.file-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.file-upload label {
  font-size: 14px;
  color: #444;
  margin-bottom: 5px;
}

.file-upload input {
  font-size: 14px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  width: 100%;
}

.file-upload input[type="file"] {
  cursor: pointer;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 20px;
}

.btn-sign, .btn-cancel {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-sign {
  background-color: #4CAF50;
  color: white;
}

.btn-sign:hover {
  background-color: #45a049;
}

.btn-cancel {
  background-color: #f44336;
  color: white;
}

.btn-cancel:hover {
  background-color: #e53935;
}

.message {
  font-size: 18px;
  color: #333;
  line-height: 1.6;
  margin-top: 15px;
  margin-left: 8px;
}