// AddZoomLink.scss

.container {
    margin: 20px;
    padding: 10px;
    font-family: Arial, sans-serif;
  }
  
  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  
    th, td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
  
    th {
      background-color: #f4f4f4;
      color: #333;
    }
  
    td {
      color: #555;
    }
  
    a {
      color: #007BFF;
      text-decoration: none;
    }
  
    a:hover {
      text-decoration: underline;
    }
  
    tr:hover {
      background-color: #f1f1f1;
    }
  }
  
  // Responsive design for smaller screens
  @media (max-width: 768px) {
    .table {
      font-size: 14px;
    }
  
    th, td {
      padding: 8px;
    }
  }
  // main.scss

$primary-color: #4CAF50;
$hover-color: #45a049;
$modal-bg-color: rgba(0, 0, 0, 0.5);
$modal-content-bg-color: white;
$button-color: #007bff;
$button-hover-color: #0056b3;
$border-color: #ddd;

.container {
  padding: 20px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.table th, .table td {
  padding: 10px;
  text-align: left;
  border: 1px solid $border-color;
}

.table th {
  background-color: #f2f2f2;
}

.btn-add-link {
  padding: 8px 16px;
  background-color: $primary-color;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;

  &:hover {
    background-color: $hover-color;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $modal-bg-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: $modal-content-bg-color;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid $border-color;
  border-radius: 4px;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
}

.modal-actions button {
  padding: 8px 16px;
  background-color: $button-color;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: $button-hover-color;
  }
}
