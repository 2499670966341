.excel-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #f5f5f5; // خلفية خفيفة بلون سكني
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 70px;

  .excel-upload-form, .file-display-container {
    width: 100%;
    text-align: center;
    margin: 10px 0;

    .upload-btn, .update-btn {
      background-color: #e63946; // لون أحمر غامق للأزرار
      color: #fff;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s ease;
margin-top: 10px;
width: 240px;
      &:hover {
        background-color: #d62828; // لون أغمق للأزرار عند التمرير
      }

      &:disabled {
        background-color: #f1a1a8; // لون وردي باهت للأزرار المعطلة
        cursor: not-allowed;
      }
    }

    .error-message {
      color: #d90429; // لون أحمر واضح للرسائل الخطأ
      font-size: 14px;
      margin-top: 5px;
    }

    .file-download-link {
      display: inline-block;
      margin: 10px 0;
      color: #e63946; // لون أحمر غامق للرابط
      font-weight: 500;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: #d62828; // لون أغمق عند التمرير
      }
    }

    h2 {
      font-size: 24px;
      color: #b22222; // لون أحمر متوازن للنصوص الكبيرة
    }

    p {
      font-size: 16px;
      color: #8b0000; // لون أحمر داكن للنصوص
      line-height: 1.5;
      margin: 10px 0;
    }
  }
}

// تصميم الإدخال
.image-upload {
  margin-bottom: 15px;

  label {
    display: block;
    font-size: 16px;
    color: #8b0000; // لون أحمر داكن للنصوص
    margin-bottom: 5px;
  }

  input[type="file"] {
    display: none;
  }

  .file-label {
    display: inline-block;
    background-color: #e63946; // لون أحمر غامق للزر
    color: #fff;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #d62828; // لون أغمق عند التمرير
    }
  }
}

// تصميم للهواتف الذكية وأحجام الشاشات الصغيرة
@media (max-width: 768px) {
  .excel-upload-container {
    padding: 15px;

    h2 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }

    .upload-btn, .update-btn {
      font-size: 14px;
      padding: 8px 15px;
    }
  }
}

@media (max-width: 480px) {
  .excel-upload-container {
    padding: 10px;

    h2 {
      font-size: 18px;
    }

    p {
      font-size: 12px;
    }

    .upload-btn, .update-btn {
      font-size: 12px;
      padding: 7px 10px;
    }
  }
}
