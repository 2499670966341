.phone-input-container {
  .react-tel-input .form-control {
    width: 100%;
    height: 38.8px;
    border: 1px solid #d8dae5;
    padding-left: 74px;
    border-radius: 3px;
  }
  .react-tel-input .selected-flag {
    width: 56px;
    padding: 0 0 0 18px;
  }
  .react-tel-input .country-list {
    box-shadow: unset;
    border: 1px solid #d8dae5;
    margin: 1px 0 10px -1px;
    border-top: unset;
    width: 276px;
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #00023f;
      border-radius: 10px;
      width: 1px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }
  .react-tel-input .flag-dropdown {
    background-color: unset;
  }
  .label{
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: rgb(20, 36, 82);
  }
  .star-required{
    color: red;
  }
  &.error{
    .form-control,.flag-dropdown    {
      border: 1px solid var(--error-static);
    }
  }
  .error-msg-container{
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--error-static);
  }
  .country-name{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: var(--element-color);
    cursor: pointer;
  }
}
