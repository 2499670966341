$primary-color: #9B1321; // أحمر جميل
$secondary-color: #f2f2f2; // رمادي فاتح (سكني)
$text-color: #333; // رمادي غامق / أسود تقريبي
$border-radius: 8px;
.dinner-container5 {
  max-height: calc(100vh - 57px);
  overflow: overlay;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb-color);
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--scrollbar-thumb-hover-color);
  }
}
.trip-form-container5 {
  // background-color: $secondary-color;
  max-width: 600px;
  margin: 10px auto;
  padding: 20px;
  border-radius: $border-radius;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: $text-color;

  .form-title {
    font-size: 1.5rem;
    color: $primary-color;
    text-align: center;
    margin-bottom: 20px;
  }
}

// تنسيق الحقول (الحقل العام)
.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid lighten($primary-color, 20%);
  border-radius: $border-radius;
  font-size: 1rem;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: darken($primary-color, 10%);
    outline: none;
  }
}

// تنسيق حقل الـ select بشكل منفصل
.select-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid lighten($primary-color, 20%);
  border-radius: $border-radius;
  font-size: 1rem;
  background-color: #fff; // خلفية بيضاء
  transition: border-color 0.3s ease;

  &:focus {
    border-color: darken($primary-color, 10%);
    outline: none;
  }
}

// تنسيق زر الإرسال
.submit-button-container {
  text-align: center;

  .submit-button2 {
    background-color: $primary-color;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: $border-radius;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken($primary-color, 15%);
    }
  }
}

// استجابة التصميم
@media (max-width: 768px) {
  .trip-form-container {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .form-title {
    font-size: 1.2rem;
  }

  .submit-button2 {
    font-size: 0.9rem;
    padding: 8px 16px;
    margin-top: 30px;
  }
}
