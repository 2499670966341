.closing-date-container {
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 8px;
    max-width: 600px;
    margin: 50px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
    h2 {
      text-align: center;
      margin-bottom: 20px;
      font-size: 1.8rem;
      color: #333;
    }
  
    .form-group {
      margin-bottom: 15px;
  
      label {
        font-weight: bold;
        margin-bottom: 8px;
        display: block;
        font-size: 1.1rem;
        color: #555;
      }
  
      select,
      input[type="datetime-local"] {
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
      }
    }
  
    .btn-submit {
      background-color: #4caf50;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      width: 100%;
      font-size: 1.2rem;
      transition: background-color 0.3s ease;
  
      &:hover {
        background-color: #45a049;
      }
    }
  
    .alert {
      padding: 10px;
      margin-bottom: 15px;
      text-align: center;
      font-weight: bold;
      border-radius: 5px;
  
      &.success {
        background-color: #4caf50;
        color: white;
      }
  
      &.error {
        background-color: #f44336;
        color: white;
      }
    }
  }
  
  // لجعل التصميم قابل للتكيف مع الشاشات الصغيرة
  @media (max-width: 768px) {
    .closing-date-container {
      padding: 20px;
      margin: 30px auto;
    }
  
    h2 {
      font-size: 1.6rem;
    }
  }
  