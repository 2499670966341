.ticket-booking {
    font-family: Arial, sans-serif;
    text-align: center;
    color: #333;
  
    .booking-hero {
      position: relative;
      width: 100%;
      height: 400px;
      // margin-bottom: 30px;
  
      .hero-image {
        width: 100%;
        height: 78%;
        object-fit: cover;
        background-size: contain;
        filter: brightness(70%); // تقليل السطوع للخلفية
      }
  
      .hero-title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 3rem;
        color: #fff;
        font-weight: bold;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.4);
        letter-spacing: 2px;
      }
    }
  
    .booking-description {
      width: 80%;
      margin: 0 auto;
      font-size: 1.2rem;
      line-height: 1.6;
      background-color: #f9f9f9; // خلفية للنص
      padding: 0px;
      border-radius: 10px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
      p {
        margin: 10px;
      }
    }
  }
  