@use "../../Global/style.scss";

.dropdown-container2 {
  width: 100%;
  position: relative;

  .label-container {
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: var(--element-color);

    .star {
      color: var(--error-static);
    }
  }

  .dropdown-header {
    width: calc(100%);
    border: 1px solid var(--border-color);
    border-radius: 3px;
    height: 37px;
    outline: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: var(--element-color);
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.error-msg {
      border: 1px solid var(--error-static);
    }

    .placeholder {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #9a9eb2;
    }

    .arrow-icon {
      margin-right: 15px;
      fill: gray;
    }
  }
  .dropdown-options {
    z-index: 1;
    width: 100%;
    border-radius: 3px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: 1px solid var(--border-color);
    background-color: #fff;
    position: absolute;
    top: 68px;
    right: 0;
    left: 0;
    max-height: 170px;
    overflow: overlay;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #00023f;
      border-radius: 10px;
      width: 1px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
    .dropdown-option {
      padding: 10px 15px;
      border-bottom: 1px solid var(--border-color);
      font-family: Arial, Helvetica, sans-serif;
      font-size: 13px;
      font-weight: 500;
      color: var(--element-color);
      cursor: pointer;
      text-align: start;
      &:hover {
        background-color: #f1f1f1;
      }
      &.selected {
        background-color: #f1f1f1;
      }
    }
  }

  .error-msg-container {
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: var(--error-static);
  }
  .search-feild {
    padding-left: 15px;
    height: 34px;
    border: none;
    width: 90%;
    outline: none;
    cursor: pointer;
    margin-left: 1px;

    &::placeholder {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #9a9eb2;
    }
  }
}
