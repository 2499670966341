.participants-component2 {
  .head {
    background-color: rgb(241, 241, 241);
    padding: 0px 20px;
    height: 60px;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .head2 {
    // background-color: rgb(241, 241, 241);
    padding: 10px 20px;
    // height: 60px;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #9a9a9a2c;
    border-top: 1px solid #9a9a9a2c;
  }

  .participant-details {
    .details-list {
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 20px;
      margin: 0px;
      // border-bottom: 1px solid #9a9a9a2c;
    }
    .participant-details {
      overflow: overlay;
      height: calc(100vh - 60px);
      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: var(--scrollbar-track-color);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--scrollbar-thumb-color);
        border-radius: 50px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--scrollbar-thumb-hover-color);
      }
    }
  }

  .view-details-button {
    background: none;
    border: none;
    color: #007bff; /* Primary link color */
    // text-decoration: underline; /* Underline to indicate it's a link */
    font-size: 16px; /* Font size for the link */
    cursor: pointer; /* Change cursor to pointer on hover */
    padding: 0;
    margin: 0;
    transition: color 0.3s ease, text-decoration 0.3s ease;
  }

  .view-rooms-button:hover,
  .view-rooms-button:focus {
    color: #0056b3; /* Darker shade for hover/focus state */
    text-decoration: none; /* Remove underline on hover/focus for cleaner look */
  }

  .view-rooms-button:active {
    color: #004085; /* Even darker shade for active state */
  }
  .participant-details {
    overflow: overlay;
    height: calc(100vh - 60px);
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: var(--scrollbar-track-color);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--scrollbar-thumb-color);
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--scrollbar-thumb-hover-color);
    }
  }
  .view-invoice-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 600;
    color: #007bff; /* Primary blue color */
    text-align: center;
    text-decoration: none;
    border: none; /* Border with the same color */
    border-radius: 5px;
    background-color: transparent; /* No background */
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .view-invoice-button:hover {
    color: #0056b3; /* Darker blue for hover */
    border-color: #0056b3; /* Change border color on hover */
    background-color: rgba(0, 123, 255, 0.1); /* Light blue background on hover */
  }
  
  .view-invoice-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Focus outline for accessibility */
  }
  
  .view-invoice-button:active {
    color: #003d80; /* Even darker blue for active state */
    border-color: #003d80;
    background-color: rgba(0, 123, 255, 0.2); /* Slightly darker background on active */
    transform: scale(0.98); /* Slightly smaller to indicate click */
  }
  
  
}
