.additional-options-stepper-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 30px;
  width: 260px;
}

.accommodation-form-steeper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 30px;
  .check-in-input-container {
    margin-top: 25px;
  }
}
.cost-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 30px;
  padding: 20px;
  border: 1px solid #d8dae5;
  border-radius: 5px;
  margin-top: 20px;
}

.next-button {
  margin-right: 30px;
  width: 200px;
  height: 45px;
  background-color: #cc0000; // Red background
  color: #fff; // White text color
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  // transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #cc0000; // Darker red on hover
  }

  &:active {
    background-color: #990000; // Even darker red on active
    transform: scale(0.98); // Slightly shrink the button on click
  }
}