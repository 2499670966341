.sponsorship-form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
  margin-top: 10px;

.sponsorship-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sponsorship-sec {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.add-new-Sponsorship {
  font-size: 1.5rem;
  color: #b91c1c; /* Modern red color */
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}

button.submit-btn {
  background-color: #b91c1c; /* Red background */
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #8b1414; /* Darker red on hover */
  }

  &:disabled {
    background-color: #f5a5a5;
    cursor: not-allowed;
  }
}

input,
textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  transition: border 0.3s ease;

  &:focus {
    border-color: #b91c1c; /* Red border when focused */
    outline: none;
  }
}

input::placeholder,
textarea::placeholder {
  color: #aaa;
}

label {
  font-weight: 500;
  margin-bottom: 5px;
  color: #333;
}
}
