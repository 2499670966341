.all-reservation-form {

  .reservation-form-header-container {
    margin: 20px;
    height: 90px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgb(242, 239, 239);
    .reservation-actions {
      display: flex;
      flex-direction: row;
      gap: 20px;
      button {
        margin-right: 30px;
        width: 270px;
        height: 45px;
        background-color: #9B1321; // Red background
        color: #fff; // White text color
        border: none;
        border-radius: 4px;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        // transition: background-color 0.3s ease, transform 0.2s ease;

        &:hover {
          background-color: #cc0000; // Darker red on hover
        }

        &:active {
          background-color: #990000; // Even darker red on active
          transform: scale(0.98); // Slightly shrink the button on click
        }
        &.disabled {
          background-color: #d6d6d6;
          color: #a1a1a1;
          cursor: not-allowed;
          opacity: 0.7;
          &:hover {
            background-color: #d6d6d6;
          }
        }
      }
    }
    .title-container {
      font-size: 19px;
      font-weight: 500;
      white-space: nowrap;
    }
  }

  .reservation-form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .view-reservation-info {
    width: 70%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    padding: 30px;
  }
  .room-form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .reservation-card-section {
    .info-reservation-section {
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
    }
    .title-reservation {
      border: 1px solid #ebecf2;
      padding: 20px;
      font-size: 16px;
      color: #9B1321;
    }

    .room-details {
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
    }
    .reservation-invoice {
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
    }
    .line {
      height: 1px;
      width: 100%;
      background-color: #ebecf2;
    }
  }
  .reservation-information-btn {
    background-color: #c83222;
    &.disabled {
      background-color: #d6d6d6;
      color: #a1a1a1;
      cursor: not-allowed;
      opacity: 0.7;
      &:hover {
        background-color: #d6d6d6;
      }
    }
  }
}
