body {
  font-family: Arial, sans-serif;
  text-align: left;
  margin: 0;
  padding: 0;
}

.stepper-page-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1.1fr 4fr;
  height: calc(100vh - 66px);

  .stepper-section {
    .back-section {
      border-right: 1px solid #d8dae5;
      padding: 26.5px 30px;
      cursor: pointer;

    }

    .stepper-container-section {
      padding: 20px;
      border: 1px solid #d8dae5;
      height: calc(100vh - 224px);
      border-bottom: none;
    }
  }

  .actions-section {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    border-top: 1px solid #d8dae5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
  }
  .next-button {
    margin-right: 30px;
    width: 200px;
    height: 45px;
    background-color: #cc0000; // Red background
    color: #fff; // White text color
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    // transition: background-color 0.3s ease, transform 0.2s ease;
  
    &:hover {
      background-color: #cc0000; // Darker red on hover
    }
  
    &:active {
      background-color: #990000; // Even darker red on active
      transform: scale(0.98); // Slightly shrink the button on click
    }
    &.disabled {
      background-color: #d6d6d6;
      color: #a1a1a1;
      cursor: not-allowed;
      opacity: 0.7;
      &:hover {
        background-color: #d6d6d6;
      }
    }
  }
  

  .current-step {
    display: flex;
    flex-direction: column;

    .header-current-step {
      height: 78.4px;
      border-bottom: 1px solid #d8dae5;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 20px;
    }

    .current-component {
      padding: 20px;
      height: calc(100vh - 224px);
      overflow: overlay;

      &::-webkit-scrollbar {
        width: 10px; /* Width of the scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Color of the track */
        border-radius: 10px; /* Rounded edges for the track */
      }

      &::-webkit-scrollbar-thumb {
        background-color: #002b5a; /* Color of the scrollbar thumb */
        border-radius: 10px; /* Rounded edges for the thumb */
        border: 3px solid #f1f1f1; /* Creates padding around the thumb */
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #002b5a; /* Darker color on hover */
      }

      /* Styles for Firefox */
      * {
        scrollbar-width: thin; /* Makes scrollbar thinner */
        scrollbar-color: #002b5a #f1f1f1; /* thumb color and track color */
      }
    }
  }
}
