.speakers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.speaker {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  width: 220px;
  overflow: hidden;
  text-align: center;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.speaker:hover {
  transform: translateY(-15px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.speaker-image {
  width: 120px;
  height: 120px;
  margin: 0 auto 15px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e0e0e0;
}

.speaker-image .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.speaker-image .image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888;
  font-weight: bold;
}

.speaker-details h3 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0;
  margin-bottom: 10px;
}

.speaker-details p {
  font-size: 14px;
  color: #555;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  text-align: center;
}

@media (max-width: 768px) {
  .speakers-container {
    gap: 15px;
  }

  .speaker {
    width: 180px;
    padding: 18px;
  }

  .speaker-details h3 {
    font-size: 16px;
  }

  .speaker-details p {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .speakers-container {
    gap: 10px;
  }

  .speaker {
    width: 150px;
    padding: 15px;
  }

  .speaker-details h3 {
    font-size: 14px;
  }

  .speaker-details p {
    font-size: 11px;
  }
}
