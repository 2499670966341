.pending-users-container {
  border-radius: 8px;
  width: 100%;
  max-width: 1700px;
  // height: calc(100vh - 80px);
  padding: 20px;
  // overflow: overlay;

  // &::-webkit-scrollbar {
  //   width: 3px;
  // }

  // &::-webkit-scrollbar-track {
  //   background: var(--scrollbar-track-color);
  // }

  // &::-webkit-scrollbar-thumb {
  //   background: var(--scrollbar-thumb-color);
  //   border-radius: 50px;
  // }

  // &::-webkit-scrollbar-thumb:hover {
  //   background: var(--scrollbar-thumb-hover-color);
  // }
  .pending-users-container h2 {
    font-size: 24px;
    font-weight: 600;
    color: #c62828;
    margin-bottom: 20px;
    text-align: center;
  }

  .Select-container {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  .Select-container .select {
    width: 200px;
    margin-right: 20px;
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid #f44336;
    background-color: #fff;
    transition: border 0.3s ease;
  }

  .Select-container .select:focus {
    border-color: #d32f2f;
    outline: none;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-top: 20px;
  }

  .table thead {
    color: #fff;
    text-align: left;
  }

  .table tbody tr {
    transition: background-color 0.3s ease;
  }

  .table tbody tr:hover {
    background-color: #f8f8f8;
  }

  .table tbody tr:last-child {
    border-bottom: none;
  }

  .error-message {
    color: #f44336;
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
  }
  .disabled-btn {
    background-color: #ccc;
    color: #666;
    cursor: unset;
    opacity: 0.6;
    border: 1px solid #aaa;
    box-shadow: none;
  }
  .submit-btn {
    padding: 10px 10px;
    font-size: 16px;
  }
  .users-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
  }
  .simple-label-value-container .value {
    white-space: normal; /* Allows text to wrap */
    word-wrap: break-word; /* Ensures long words break if necessary */
    word-break: break-word; /* Alternative for word wrapping */
    max-width: 100%; /* Prevents the text container from exceeding its parent */
    overflow-wrap: break-word;
  }
  .custom-form-wrapper .form-section {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(99vh - 59px);
    overflow: overlay;
    background-color: #fff;
  }

  .actions-buttons-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    button {
      padding: 8px 15px;
      color: white;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 5px;
      transition: background-color 0.3s ease;
      background-color: #c62828;
    }

    .disabled-btn {
      background-color: #7a7a7a !important;
    }
  }
}
